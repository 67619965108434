/* eslint-disable no-prototype-builtins */
/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
    Button,
    Col,
    Collapse,
    Empty,
    Spin,
    Tooltip, Typography,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import {handleApiError} from '../../../../services/utils/errorHandler';
import {RoutesContext} from '../../index';
import {withConfigContext} from '../../../../components/ConfigContext';
import moment from 'moment';
import 'moment-timezone';
import MeterReadingTable from './MeterReadingTable';
import styles from './styles.module.css';
import {
    OneToOneOutlined,
    ReloadOutlined,
    SearchOutlined
} from '@ant-design/icons';
import Filter from '../../components/Filter';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import {
    meterReadings
} from '../../../../services/utils/functions';
import {SUBTYPE_MAPPING} from '../../../../constant/TableData';

const {Title} = Typography;
const {Panel} = Collapse;

const routes = (appName, t) => {
    return [
        {
            path: `/${appName}`,
            breadcrumbName: t('home_menu_home'),
        },
        {
            breadcrumbName: t('home_menu_reading'),
        },
    ];
};

class MeterReading extends React.Component {
    filterValues = '';
    const

    // eslint-disable-next-line prettier/prettier
    constructor(props) {
        super(props);
        this.config = this.props.context;
        this.state = {
            devices: [],
            count: 0,
            pagination: {},
            loading: false,
            selectedRows: [],
            searchFields: [],
            isModalVisible: false,
        };
        const {t} = this.props;
        this.columns = [
            {
                title: t('label_serial'),
                dataIndex: 'serial',
                key: 'serial',
                render: (serial) => (
                    <Link to={`/sub-metering-mgt/meter/view?serial=${serial}`} target="_blank">{serial}</Link>
                ),
            },
            {
                title: t('label_accountNo'),
                dataIndex: 'customerRef',
                key: 'customerRef',
            },
            {
                title: t('label_operationId'),
                dataIndex: 'operationId',
                key: 'operationId',
            },

            {
                title: t('label_date'),
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: t('label_time'),
                dataIndex: 'time',
                key: 'time',
            },
            {
                title: t('label_totalImportKWh'),
                dataIndex: 'totalImport',
                key: 'totalImport',
                render: (totalImport) => (totalImport ? totalImport : '-'),
            },
            {
                title: t('label_totalExportKWh'),
                dataIndex: 'totalExport',
                key: 'totalExport',
                render: (totalExport) => (totalExport ? totalExport : '-'),
            },
            {
                title: t('label_totalImportKWh'),
                dataIndex: 'totalMaxDemandImportPV1',
                key: 'totalMaxDemandImportPV1',
                render: (totalMaxDemandImportPV1) => (totalMaxDemandImportPV1 ? totalMaxDemandImportPV1 : '-'),
            },
            {
                title: t('label_remoteRelayStatus'),
                dataIndex: 'remoteRelayStatus',
                key: 'remoteRelayStatus',
                render: (remoteRelayStatus) => (remoteRelayStatus ? remoteRelayStatus : '-'),
            },
        ];
    }

    componentDidMount() {
        this.context.setCurrentRoute('allMeterReading');
        const {t} = this.props;

        // initial loading state to true
        this.setState({loading: true});

        // checking for query parameters in the URL
        const queryParams = new URLSearchParams(window.location.search);
        const serial = queryParams.get('serial');
        const operationId = queryParams.get('operationId');
        const status = queryParams.get('status');
        const startTimestamp = queryParams.get('startTimestamp');
        const endTimestamp = queryParams.get('endTimestamp');
        const operationCode = queryParams.get('operationCode');

        // set the initial state for search fields
        const initialSearchFields = {
            operationStatus: status ? [status] : [],
            operationId: operationId || '',
            operationCode: operationCode || '',
            serial: serial ? [serial] : [],
            createdDay: startTimestamp ? moment(parseInt(startTimestamp, 10)) : null,
            updatedDay: endTimestamp ? moment(parseInt(endTimestamp, 10)) : null,
        };

        const fields = [
            {
                name: 'subTypeId',
                label: t('label_subTypeId'),
                placeholder: t('label_subTypeId'),
                type: 'multipleSelect',
                values: Object.keys(this.config.subTypeId).map(
                    key => this.config.subTypeId[key],
                ),
            },
            {
                name: 'serial',
                label: t('label_serials'),
                placeholder: t('label_serials'),
                type: 'creatableSelect',
                mode: 'tags',
                values: []
            },
            {
                name: 'operationId',
                label: t('label_operationId'),
                placeholder: t('label_operationId'),
                type: 'input',
            },
            {
                name: 'operationCode',
                label: t('label_operationCode'),
                placeholder: t('label_operationCode'),
                type: 'select',
                values: ['BILLING_REGISTERS_RETRIEVE', 'REMOTE_RELAY_ON', 'REMOTE_RELAY_OFF', 'TIME_SYNC', 'STATUS_RETRIEVE',],
            },
            {
                name: 'createdDay',
                label: t('label_startDate'),
                placeholder: t('label_startDate'),
                type: 'calender',
            },
            {
                name: 'updatedDay',
                label: t('label_endDate'),
                placeholder: t('label_endDate'),
                type: 'calender',
            },
        ];

        // set the state with the initial search fields
        this.setState({
            searchFields: fields.map(field => ({
                ...field,
                value: initialSearchFields[field.name],
            })),
        });

        // fetch data based on query parameters if has
        this.fetchOperationFilters({}, initialSearchFields);
    }

    calenderDates = (day, propertyName) => {
        let readingFilters = '';
        if (propertyName === 'createdDay') {
            readingFilters =
                readingFilters +
                '&startTimestamp=' + day.valueOf();
                // Math.floor(day.valueOf() / 1000);

            return readingFilters;
        }

        if (propertyName === 'updatedDay') {
            readingFilters =
                readingFilters +
                '&endTimestamp=' + day.valueOf();
                // Math.floor(day.valueOf() / 1000);

            return readingFilters;
        }
    };

    fetchOperationFilters = (params, values) => {
        let readingFilters = '';

        if (values.hasOwnProperty('subTypeId')) {
            for (let i = 0; i < values.subTypeId.length; i++) {
                const subtypeKey = SUBTYPE_MAPPING[values.subTypeId[i]];
                readingFilters = readingFilters + '&subTypeId=' + subtypeKey;
            }
        }
        if (values.hasOwnProperty('serial')) {
            for (let i = 0; i < values.serial.length; i++) {
                readingFilters =
                    readingFilters + '&serial=' + values.serial[i];
            }
        }
        if (values.hasOwnProperty('operationId') && values.operationId.trim() !== '') { // with this values.operationId.trim() !== '' avoided null value set to operationId
            readingFilters = readingFilters + '&operationId=' + values.operationId.trim();
        }
        if (values.hasOwnProperty('operationCode') && values.operationCode.trim() !== '') {
            readingFilters = readingFilters + '&operationCode=' + values.operationCode.trim();
        }
        // eslint-disable-next-line no-unused-vars
        for (let property in values) {
            if (property === 'createdDay' && values[property]) {
                let selectedFromDate = moment(values.createdDay);
                readingFilters +=
                    this.calenderDates(selectedFromDate, property);
            }
            if (property === 'updatedDay' && values[property]) { // Add this values[property] otherwise when coming with query params this may the timestamp as NaN value
                let selectedToDate = moment(values.updatedDay);
                readingFilters +=
                    this.calenderDates(selectedToDate, property);
            }
        }
        this.filterValues = readingFilters;
        this.setState({pagination: {current: 1}});
        this.getMeters(
            {
                page: 1,
            },
            readingFilters,
        );

    };

    getMeters = (params = {}, filters = '') => {
        const {t} = this.props;
        this.setState({loading: true});
        // get current page
        const currentPage =
            params.hasOwnProperty('page') && params.page ? params.page : 1;

        let limit = 10;
        if (params.results) {
            limit = params.results;
        }

        const extraParams = {
            offset: limit * (currentPage - 1), // calculate the offset
            limit: limit,
        };

        let encodedExtraParams = Object.keys(extraParams)
            .map(key => key + '=' + extraParams[key])
            .join('&');

        // const paramSet = encodedExtraParams;
        if (filters !== '') {
            encodedExtraParams = encodedExtraParams + filters;
        }
        axios
            .get(
                window.location.origin +
                this.config.serverConfig.invoker.uri +
                this.config.serverConfig.invoker.meterMgt +
                '/admin/devices/readings?' +
                encodedExtraParams,
            )
            .then(res => {
                if (res.status === 200) {
                    const pagination = {...this.state.pagination};
                    const metersData = res.data.data.meterReadings.map(meterReading => ({
                        key: meterReading.id, // added this for using unique row identifier to antd table row
                        serial: meterReading.serial,
                        customerRef: meterReading.customerRef,
                        operationId: meterReading.operationId,
                        operationCode: meterReading.operationCode,
                        date: meterReading.response['0.9.2_0'],
                        time: meterReading.response['0.9.1_0'],
                        totalImport: meterReading.response['1.8.0_0'],
                        totalExport: meterReading.response['2.8.0_0'],
                        totalImportPV1: meterReading.response['1.8.0*01_0'],
                        totalExportPV1: meterReading.response['2.8.0*01_0'],
                        totalMaxDemandImportPV1: meterReading.response['9.6.0*01_0'],
                        remoteRelayStatus: meterReading.response.CSRQ_RL,
                        response: meterReading.response,
                    }));
                    this.setState({
                        devices: metersData,
                        count: res.data.data.count,
                        pagination,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                handleApiError(error, t('api_getOperationError'), t);
                this.setState({loading: false});
            });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager,
        });
        this.getMeters(
            {
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            },
            this.filterValues,
        );
    };

    showModal = () => {
        this.setState({
            isModalVisible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
        });
    };

    getDetailsUI = (response) => {
        if (!response || Object.keys(response).length === 0) {
            return (
                <Empty
                    className={styles.customEmpty}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            );
        }

        return (
            <div>
                <div className={styles.responseRow}>
                    <div className={styles.flexContainer}>
                        {Object.keys(meterReadings)
                            .filter((key) => response[key] !== undefined)
                            // filter and show the only response fields that coming from the 'response'
                            .map((key, index) => (
                                <p key={index}>
                                    <strong>{meterReadings[key].text}&nbsp;: </strong>
                                    {response[key]}
                                </p>
                            ))}
                    </div>
                </div>
            </div>
        );
    };

    refreshOperationLog = () => {
        const {pagination} = this.state;
        this.getMeters({
            page: pagination.current,
        });
    };

    render() {
        const {devices, pagination, loading, selectedNodeType} = this.state;
        const {t} = this.props;
        // Define dynamic category options based on nodeType
        const categoryOptions =
            // eslint-disable-next-line no-nested-ternary
            selectedNodeType === 'INTERNAL'
                ? ['INTERNAL_DISTRIBUTION', 'INTERNAL_TESTING']
                : selectedNodeType === 'BILLING'
                    ? ['BILLING_BULK', 'BILLING_PAID']
                    : [];
        return (
            <div>
                <div>
                    <PageHeader
                        className={styles.pageHeader}
                        title={
                            <Title level={1}>
                                        <span className={styles.icon}>
                                          <OneToOneOutlined/>
                                        </span>
                                {t('home_menu_meter_readings')}
                            </Title>
                        }
                        ghost={false}
                        breadcrumb={
                            <BreadcrumbComponent
                                breadcrumbList={routes(this.config.appName, t)}
                            />
                        }
                    >
                        {/* <Paragraph className={styles.description}>*/}
                        {/*    {t('meter_inventory_description')}*/}
                        {/* </Paragraph>*/}
                    </PageHeader>
                    <div className={styles.table}>
                        <Collapse
                            bordered={false}
                            expandIcon={() => <SearchOutlined/>}
                            className={styles.searchCollapse}
                        >
                            <Panel
                                header={t('form_searchReadings')}
                                key="1"
                                style={{borderColor: '#fff'}}
                            >
                                <Filter
                                    filters={this.state.searchFields}
                                    callback={(params, values) =>
                                        this.fetchOperationFilters({}, values)
                                    }
                                    onNodeTypeChange={(selectedNodeType) =>
                                        this.setState({selectedNodeType})
                                    }
                                    categoryOptions={categoryOptions}
                                />
                            </Panel>
                        </Collapse>
                        <div>
                            <Col span={4} offset={22}>
                                <Tooltip
                                    placement="bottom"
                                    title={t('label_refreshMeterReading')}
                                    autoAdjustOverflow={true}
                                >
                                    <Button
                                        type="link"
                                        shape="circle"
                                        icon={<ReloadOutlined/>}
                                        onClick={this.getMeters}
                                        size={'default'}
                                        style={{margin: '2px'}}
                                    >
                                        {t('label_refresh')}
                                    </Button>
                                </Tooltip>
                            </Col>
                            {loading &&
                                <div style={{textAlign: 'center'}}>
                                    <Spin/>
                                </div>}
                            {!loading > 0 && (
                                <MeterReadingTable
                                    devices={devices}
                                    columns={this.columns}
                                    pagination={pagination}
                                    loading={loading}
                                    count={this.state.count}
                                    handleTableChange={this.handleTableChange}
                                    getDetailsUI={this.getDetailsUI}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MeterReading.contextType = RoutesContext;
export default withConfigContext(withTranslation()(MeterReading));

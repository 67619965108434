/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Button,
  Col,
  Collapse,
  Empty,
  Result,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import { RoutesContext } from '../../index';
import { withConfigContext } from '../../../../components/ConfigContext';
import moment from 'moment';
import 'moment-timezone';
import MeterInventoryTable from './MeterInventoryTable';
import styles from './styles.module.css';
import {
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Filter from '../../components/Filter';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import {
  getBranchName,
  getDepotName, getUnixTime
} from "../../../../services/utils/functions";
import { SUBTYPE_MAPPING } from '../../../../constant/TableData';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import Authorized from '../../../../components/Authorized';
import EditDevice from './components/EditDevice';
import { CSVLink } from 'react-csv';
import { connect } from "react-redux";
import { subHours } from "date-fns";
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;
import configs from '../../../../../public/conf/config.json';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home')
    },
    {
      breadcrumbName: t('home_menu_meterInventory')
    }
  ];
};

class MeterInventory extends React.Component {
  filterValues = '';
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      devices: [],
      count: 0,
      pagination: {},
      loading: false,
      selectedRows: [],
      searchFields: [],
      exportDataList: [],
      isModalVisible: false
    };
    const { t } = this.props;
    let link = null;
    const rightNow = new Date();
    this.columns = [
      {
        title: t('label_meter'),
        dataIndex: 'name',
        key: 'name',
        render: (name, record, typeProperties) => (
          <Space>
            <Link
              to={`/sub-metering-mgt/load/profile-data?serial=${record.serial}&startTimestamp=${subHours(new Date(), 3).getTime()}&endTimestamp=${new Date().getTime()}&branch=${this.props.selectedBranch}`}
              target="_blank"
            >
              {name}
            </Link>
          </Space>
        )
      },
      {
        title: t('label_facility'),
        dataIndex: 'branch',
        key: 'branch',
        render: branch => getBranchName(branch)
      },
      {
        title: t('label_subTypeId'),
        dataIndex: 'subtype',
        key: 'subtype',
        render: subtypeId => this.config.subTypeId[subtypeId] || '-'
      },
      {
        title: t('label_category'),
        dataIndex: 'nodeType',
        key: 'nodeType'
      },
      {
        title: t('label_state'),
        dataIndex: 'state',
        key: 'state',
        // eslint-disable-next-line react/display-name
        render: state => {
          const status = state.toLowerCase();
          let color;
          let backGroundColor;
          switch (status) {
            case 'active':
              backGroundColor = '#c4e0c6';
              color = '#14403e';
              break;
            case 'created':
              backGroundColor = '#8fc4ff';
              color = '#294083';
              break;
            case 'removed':
              backGroundColor = '#ff9999';
              color = '#821212';
              break;
            case 'inactive':
              backGroundColor = '#f9dd9e';
              color = '#91681a';
              break;
            case 'blocked':
              backGroundColor = '#333333';
              color = '#ffffff';
              break;
            case 'unreachable':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'unclaimed':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'configured':
              backGroundColor = '#b2d3fa';
              color = '#0f3b72';
              break;
            case 'assigned':
              backGroundColor = '#c4e0c6';
              color = '#14403e';
              break;
            case 'defective':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'disenrollment_requested':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'ready_to_connect':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'returned':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'return_pending':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'suspended':
              backGroundColor = '#ff9999';
              color = '#821212';
              break;
            case 'warranty_pending':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'warranty_replaced':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
            case 'warranty_sent':
              backGroundColor = '#cccccc';
              color = '#333333';
              break;
          }
          return (
            <span
              style={{
                display: 'inline-block',
                width: 120,
                textAlign: 'center',
                color: color,
                background: backGroundColor,
                padding: '3px 10px 3px 10px',
                borderRadius: 7
              }}
            >
              {t(`label_${status}`)}
            </span>
          );
        }
      },
      {
        title: 'Action',
        dataIndex: 'deviceIdentifier',
        key: 'actions',
        align: 'right',
        width: 25,
        // eslint-disable-next-line react/display-name
        render: (data, row) => {
          return (
            <Space>
              <EditDevice
                view={'meterInventoryTable'}
                device={row}
                typeProperties={this.state.typeProperties}
                callback={this.reloadMeterInventoryTable}
                disabled={false}
              />
            </Space>
          );
        }
      }
    ];
    // bind the exportData function to the current instance
    this.exportData = this.exportData.bind(this);

    // Create the ref
    this.csvLinkEl = React.createRef();
  }

  reloadMeterInventoryTable = () => {
    this.setState({ pagination: { current: 1 } });
    this.getMeters({
      page: 1
    });
  };

  componentDidMount() {
    this.context.setCurrentRoute('allMeterInventory');
    const rightNow = new Date();
    const { t } = this.props;
    this.fetchProperties();
    this.setState({ loading: true });
    const fields = [
      {
        name: 'subTypeId',
        label: t('label_subTypeId'),
        placeholder: t('label_subTypeId'),
        type: 'multipleSelect',
        values: this.config.subTypeId
          ? Object.keys(this.config.subTypeId).map(
              key => this.config.subTypeId[key]
            )
          : []
      },
      {
        name: 'meterStatus',
        label: t('label_status'),
        placeholder: t('label_status'),
        type: 'multipleSelect',
        values: this.config.meterStatus
          ? Object.keys(this.config.meterStatus).map(
              key => this.config.meterStatus[key]
            )
          : []
      },
    ];

    this.setState({
      searchFields: fields
    });
    this.fetchOperationFilters({}, { branch: this.props.selectedBranch });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedBranch !== prevProps.selectedBranch) {
      this.fetchOperationFilters({}, { branch: this.props.selectedBranch });
    }
  }

  fetchOperationFilters = (params, values) => {
    let inventoryFilters = '';

    if (values.hasOwnProperty('subTypeId')) {
      for (let i = 0; i < values.subTypeId.length; i++) {
        const subtypeKey = SUBTYPE_MAPPING[values.subTypeId[i]];
        inventoryFilters = inventoryFilters + '&subTypeId=' + subtypeKey;
      }
    }
    if (values.hasOwnProperty('meterStatus')) {
      for (let i = 0; i < values.meterStatus.length; i++) {
        inventoryFilters =
          inventoryFilters + '&status=' + values.meterStatus[i];
      }
    }
    if (values.hasOwnProperty('nodeType') && values.nodeType.trim() !== '') {
      inventoryFilters =
        inventoryFilters + '&nodeType=' + values.nodeType.trim();
    }
    if (values.hasOwnProperty('branch') && values.branch !== '') {
      inventoryFilters = inventoryFilters + '&branch=' + values.branch;
    }
    if (values.hasOwnProperty('category') && values.category.trim() !== '') {
      inventoryFilters =
        inventoryFilters + '&category=' + values.category.trim();
    }
    if (
      values.hasOwnProperty('substationId') &&
      values.substationId.trim() !== ''
    ) {
      inventoryFilters =
        inventoryFilters + '&substationId=' + values.substationId.trim();
    }
    if (
      values.hasOwnProperty('hasEBilling') &&
      values.hasEBilling.trim() !== ''
    ) {
      inventoryFilters =
        inventoryFilters + '&hasEBilling=' + values.hasEBilling.trim();
    }
    if (values.hasOwnProperty('hasExport') && values.hasExport.trim() !== '') {
      inventoryFilters =
        inventoryFilters + '&hasExport=' + values.hasExport.trim();
    }
    if (values.hasOwnProperty('assigned') && values.assigned.trim() !== '') {
      inventoryFilters =
        inventoryFilters + '&assigned=' + values.assigned.trim();
    }
    if (values.hasOwnProperty('poleId') && values.poleId.trim() !== '') {
      inventoryFilters = inventoryFilters + '&poleId=' + values.poleId.trim();
    }
    if (values.hasOwnProperty('serial')) {
      for (let i = 0; i < values.serial.length; i++) {
        inventoryFilters = inventoryFilters + '&serial=' + values.serial[i];
      }
    }
    if (values.hasOwnProperty('imei')) {
      for (let i = 0; i < values.imei.length; i++) {
        inventoryFilters = inventoryFilters + '&imei=' + values.imei[i];
      }
    }
    if (values.hasOwnProperty('ipAddress')) {
      for (let i = 0; i < values.ipAddress.length; i++) {
        inventoryFilters =
          inventoryFilters + '&ipAddress=' + values.ipAddress[i];
      }
    }

    this.filterValues = inventoryFilters;
    this.setState({ pagination: { current: 1 } });
    this.getMeters(
      {
        page: 1
      },
      inventoryFilters
    );
  };
  exportData() {
    this.setState({ exportData: true });
    this.getData();
  }

  // to retrieve all records from the axios call without limiting the number of records, can set the limit parameter to a high value
  getData() {
    const { t } = this.props;
    this.setState({ loading: true });

    const getAllRecords = async (offset = 0, limit = 100) => {
      try {
        const statusString =
          '&status=ACTIVE&status=INACTIVE&status=CREATED&status=READY_TO_CONNECT&status=CONFIGURED&status=RETURNED&status=UNREACHABLE&status=ASSIGNED&status=DISENROLLMENT_REQUESTED';
        let filterString = '';
        if (!this.filterValues.includes('status')) {
          filterString = this.filterValues + statusString;
        } else {
          filterString = this.filterValues;
        }
        const response = await axios.get(
          window.location.origin +
              configs.serverConfig.invoker.uri +
              configs.serverConfig.invoker.meterMgt +
            `/devices?${filterString}&offset=${offset}&limit=${limit}&branch=${this.props.selectedBranch}`
        );

        if (response.status === 200) {
          const exportData = response.data.data;

          let data = [];

          if (exportData.devices && exportData.devices.length > 0) {
            exportData.devices.forEach((device, index) => {
              if (device) {
                data.push({
                  name: device.name,
                  serial: device.deviceIdentifier,
                  status: device.enrolmentInfo.status,
                  ipAddress:
                    device.properties?.find(prop => prop.name === 'IP_ADDRESS')
                      ?.value || '-',
                  imei:
                    device.properties?.find(
                      prop => prop.name === 'COM_MODULE_IMEI'
                    )?.value || '-',
                  branch:
                    device.properties?.find(prop => prop.name === 'BRANCH')
                      ?.value || '-',
                  subtype: this.config.subTypeId[
                    (device.properties?.find(
                      prop => prop.name === 'SUB_TYPE_ID'
                    )?.value)
                  ]
                });
              }
            });
          }
          return data;
        }
      } catch (error) {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Usage') }),
          t
        );
        throw error;
      }
    };
    const fetchData = async () => {
      let allRecords = [];
      let offset = 0;
      const limit = 100; // can adjust this value if needed

      try {
        while (true) {
          const records = await getAllRecords(offset, limit);

          if (records.length === 0) {
            break;
          }

          allRecords = [...allRecords, ...records];
          offset += limit;
        }

        this.setState({ exportDataList: allRecords, loading: false }, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
      } catch (error) {
        this.setState({ loading: false });
      }
    };

    fetchData();
  }

  fetchProperties() {
    this.setState({ loading: true });
    const { t } = this.props;
    axios
      .get(
        `${window.location.origin}${configs.serverConfig.invoker.uri}/device-mgt/v1.0/device-types/power-meter`
      )
      .then(res => {
        const properties = res.data.data.deviceTypeMetaDefinition.properties;
        this.setState({
          typeProperties: properties,
          loading: false
        });
      })
      .catch(error => {
        handleApiError(error, t('api_getDevicesError'), t);
        this.setState({ loading: false });
      });
  }

  getMeters = (params = {}, filters = '') => {
    const { t } = this.props;
    this.setState({ loading: true });
    // get current page
    const currentPage =
      params.hasOwnProperty('page') && params.page ? params.page : 1;

    let limit = 10;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: limit * (currentPage - 1), // calculate the offset
      limit: limit,
      branch: this.props.selectedBranch
    };

    let encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    // const paramSet = encodedExtraParams;
    if (filters !== '') {
      encodedExtraParams = encodedExtraParams + filters;
    }
    const statusString =
      '&status=ACTIVE&status=INACTIVE&status=CREATED&status=READY_TO_CONNECT&status=CONFIGURED&status=RETURNED&status=UNREACHABLE&status=ASSIGNED&status=DISENROLLMENT_REQUESTED';

    if (!encodedExtraParams.includes('status')) {
      encodedExtraParams = encodedExtraParams + statusString;
    }
    axios
      .get(
        window.location.origin +
            configs.serverConfig.invoker.uri +
            configs.serverConfig.invoker.meterMgt +
          '/devices?requireDeviceInfo=true&type=power-meter&' +
          encodedExtraParams
      )
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          const metersData = res.data.data.devices.map(device => ({
            serial: device.deviceIdentifier,
            name: device.name,
            imei:
              device.properties?.find(prop => prop.name === 'COM_MODULE_IMEI')
                ?.value || '-',
            branch:
              device.properties?.find(prop => prop.name === 'BRANCH')?.value ||
              '-',
            depot:
              device.properties?.find(prop => prop.name === 'DEPOT')?.value ||
              '-',
            substationId:
              device.properties?.find(prop => prop.name === 'SUBSTATION_ID')
                ?.value || '-',
            hasExport:
              device.properties?.find(prop => prop.name === 'HAS_EXPORT')
                ?.value || '-',
            hasEBilling:
              device.properties?.find(prop => prop.name === 'HAS_E_BILLING')
                ?.value || '-',
            isFeederEnd:
              device.properties?.find(prop => prop.name === 'IS_FEEDER_END')
                ?.value || '-',
            poleID:
              device.properties?.find(prop => prop.name === 'POLE_ID')?.value ||
              '-',
            assignedOn:
              device.properties?.find(prop => prop.name === 'ASSIGNED_ON')
                ?.value || '-',
            fwVersion:
              device.properties?.find(prop => prop.name === 'METER_FW_VER')
                ?.value || '-',
            subtype:
              device.properties?.find(prop => prop.name === 'SUB_TYPE_ID')
                ?.value || '-',
            state: device.enrolmentInfo.status,
            ipAddress:
              device.properties?.find(prop => prop.name === 'IP_ADDRESS')
                ?.value || '-',
            nodeType:
              device.properties?.find(prop => prop.name === 'NODE_TYPE')
                ?.value || '-',
            accountNo:
              device.properties?.find(prop => prop.name === 'CUSTOMER_REF')
                ?.value || '-',
            properties: device.properties,
            description: device.description,
            type: device.type
          }));
          this.setState({
            devices: metersData,
            count: res.data.data.count,
            pagination,
            loading: false,
            exportDataList: metersData // Set export data to state
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_getDevicesError'), t);
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager
    });
    this.getMeters(
      {
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      },
      this.filterValues
    );
  };

  showModal = () => {
    this.setState({
      isModalVisible: true
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    });
  };

  // activity details in expanded row
  getDetailsUI = properties => {
    const { t } = this.props;
    const propertiesToShow = ['assignedOn', 'fwVersion'];

    if (
      !properties ||
      (Array.isArray(properties) && properties.length === 0) ||
      (typeof properties === 'object' && Object.keys(properties).length === 0)
    ) {
      return (
        <Empty
          className={styles.customEmpty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }

    // Below is iterating properties and showing them
    return (
      <div>
        <div className={styles.responseRow}>
          <div className={styles.flexContainer}>
            {propertiesToShow.map((property, index) => {
              let displayValue = Array.isArray(properties)
                ? properties.find(prop => prop.name === property)?.value
                : properties[property] || '-';

              // Special handling for 'branch' and 'depot' property
              if (property === 'branch') {
                displayValue = getBranchName(displayValue);
              }
              if (property === 'depot') {
                const branchKey = properties.branch;
                displayValue = getDepotName(branchKey, displayValue);
              }

              return (
                <p key={index} className={styles.property}>
                  <strong>{t(`label_${property}`)}: &nbsp;</strong>{' '}
                  {displayValue}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { devices, pagination, loading, selectedNodeType } = this.state;
    const { t } = this.props;

    const data = this.state.exportDataList;
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    today = mm + '-' + dd + '-' + yyyy;
    const filename = `MeterInventory_${moment().format('YYYY-MM-DD')}.csv`;
    let path = `/sub-metering-mgt/public/documents/${this.config.userDomain}/`;

    const headers = [
      { label: 'SERIAL', key: 'serial', width: 100 },
      { label: 'NAME', key: 'name' },
      { label: 'METER_TYPE', key: 'subtype' },
      { label: 'STATUS', key: 'status' },
      { label: 'IP_ADDRESS', key: 'ipAddress' },
      { label: 'IMEI', key: 'imei' }
    ];

    // Define dynamic category options based on nodeType
    const categoryOptions =
      selectedNodeType === 'INTERNAL'
        ? ['INTERNAL_DISTRIBUTION', 'INTERNAL_TESTING']
        : selectedNodeType === 'BILLING'
        ? ['BILLING_BULK', 'BILLING_PAID']
        : [];

    return (
      <div>
        <HtmlComments
          permission={
            '/permission/admin/device-mgt/rmr/device/admin/meter-inventory'
          }
        />
        <Authorized
          scope={['perm:rmr:admin:meter-inventory']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                ghost={false}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(this.config.appName, t)}
                  />
                }
              ></PageHeader>
              <div className={styles.table}>
                <Row gutter={[16, 16]} justify="space-between">
                  <Col xs={8} sm={8} md={16} lg={16}>
                    <Collapse
                      bordered={false}
                      expandIcon={() => <SearchOutlined />}
                      className={styles.searchCollapse}
                    >
                      <Panel
                        header={t('form_searchDevices')}
                        key="1"
                        style={{ borderColor: '#fff', fontSize: '12px' }}
                      >
                        <Filter
                          filters={this.state.searchFields}
                          callback={(params, values) =>
                            this.fetchOperationFilters({}, values)
                          }
                          onNodeTypeChange={selectedNodeType =>
                            this.setState({ selectedNodeType })
                          }
                          categoryOptions={categoryOptions}
                        />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col
                    xs={16}
                    sm={16}
                    md={8}
                    lg={8}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      width: '100%'
                    }}
                  >
                    <div style={{ textAlign: 'right', width: '100%' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={this.exportData}
                        disabled={this.state.count === 0}
                        style={{ marginRight: '20px', fontSize: '12px' }}
                      >
                        Export CSV
                      </Button>
                      <CSVLink
                        filename={filename}
                        data={data}
                        headers={headers}
                        ref={this.csvLinkEl}
                      />
                      <Tooltip
                        placement="bottom"
                        title={t('label_refreshMeterInventory')}
                        autoAdjustOverflow={true}
                      >
                        <Button
                          type="link"
                          shape="circle"
                          icon={<ReloadOutlined />}
                          onClick={this.getMeters}
                          size={'default'}
                          style={{ margin: '2px', fontSize: '12px' }}
                        >
                          {t('label_refresh')}
                        </Button>
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
                <div>
                  {loading && (
                    <div style={{ textAlign: 'center', marginRight: '20px' }}>
                      <Spin />
                    </div>
                  )}
                  {!loading > 0 && (
                    <MeterInventoryTable
                      devices={devices}
                      columns={this.columns}
                      pagination={pagination}
                      loading={loading}
                      count={this.state.count}
                      handleTableChange={this.handleTableChange}
                      getDetailsUI={this.getDetailsUI}
                    />
                  )}
                </div>
              </div>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

MeterInventory.contextType = RoutesContext;

const mapStateToProps = (state) => ({
  selectedBranch: state.branch.value
});

const ConnectedMeterInventory = connect(mapStateToProps)(
  withConfigContext(withTranslation()(MeterInventory))
);

export default ConnectedMeterInventory;

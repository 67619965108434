/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Button, Col, Radio, Select, Tooltip } from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import CorrectiveActions from '../CorrectiveAction';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';
const { Option } = Select;

class SelectPolicyType extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      correctivePoliciesList: [],
      activeFeatureList: [],
    };
  }

  componentDidMount() {
    this.fetchActiveFeatures(this.props.activeFeatures);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeFeatures !== this.props.activeFeatures) {
      this.fetchActiveFeatures(this.props.activeFeatures);
    }
  }

  fetchActiveFeatures = activeFeatures => {
    const activeFeatureList = [...activeFeatures];
    this.setState({
      activeFeatureList,
    });
    this.fetchPolicies();
  };

  setUpdatedFeatureData = (index, feature) => {
    let { activeFeatureList } = this.state;
    activeFeatureList[index] = feature;

    this.setState({ activeFeatureList: activeFeatureList });
  };

  // generate payload using Select policy type
  onFinish = values => {
    const { policyData } = this.props;
    const { activeFeatureList } = this.state;
    let payload = {};

    // check whether policy is editing with old payload version
    if (policyData && !policyData.policyPayloadVersion) {
      if (values.correctiveActions === 'NONE') {
        values.correctiveActions = [];
      } else {
        values.correctiveActions = [JSON.parse(values.correctiveActions)];
      }
      payload = { policyTypeData: values };
    }

    // check whether policy is adding or editing with new payload version
    if (!policyData || policyData.policyPayloadVersion) {
      const policyType = {
        policyType: values.policyType,
      };
      payload = {
        policyTypeData: policyType,
        configureProfileData: activeFeatureList,
      };
    }
    this.props.setPolicyPayloadData(payload);
    this.props.setNextStep();
  };

  fetchPolicies = () => {
    const { t } = this.props;
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/policies';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          let policies = res.data.data.policies;
          let correctivePolicies = [];
          for (let i = 0; i < policies.length; i++) {
            if (policies[i].policyType === 'CORRECTIVE') {
              let policyDetails = {
                actionType: 'POLICY',
                policyId: policies[i].profileId,
              };
              correctivePolicies.push(
                <Option key={JSON.stringify(policyDetails)}>
                  {policies[i].policyName}
                </Option>,
              );
            }
          }
          this.setState({
            correctivePoliciesList: correctivePolicies,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_policies') }),
          t,
        );

        this.setState({ loading: false });
      });
  };

  handlePolicyTypes = event => {
    if (event.target.value === 'GENERAL') {
      document.getElementById('generalPolicySubPanel').style.display = 'block';
    } else {
      document.getElementById('generalPolicySubPanel').style.display = 'none';
    }
  };

  // eslint-disable-next-line complexity
  render() {
    const { policyData, disabled, t } = this.props;
    const { activeFeatureList, correctivePoliciesList } = this.state;
    let correctiveActions;
    if (
      policyData &&
      !policyData.policyPayloadVersion &&
      policyData.correctiveActions.length > 0
    ) {
      correctiveActions = policyData.correctiveActions.map(correctiveAction => {
        return JSON.stringify(correctiveAction);
      });
    } else {
      correctiveActions = 'NONE';
    }

    return (
      <div>
        <Form
          name="selectPolicyType"
          onFinish={this.onFinish}
          initialValues={{
            policyType: policyData ? policyData.policyType : 'GENERAL',
            correctiveActions: policyData ? correctiveActions : 'NONE',
          }}
        >
          <Form.Item
            style={{ display: !disabled ? 'block' : 'none' }}
            name="policyType"
          >
            <Radio.Group onChange={this.handlePolicyTypes}>
              <Radio value="GENERAL">{t('label_generalPolicy')}</Radio>
              <Radio value="CORRECTIVE">{t('label_correctivePolicy')}</Radio>
            </Radio.Group>
          </Form.Item>
          <div id="generalPolicySubPanel" style={{ display: 'block' }}>
            {(!policyData || policyData.policyPayloadVersion) &&
              activeFeatureList.length > 0 && (
                <div>
                  {activeFeatureList.map((feature, index) => {
                    if (!feature.correctiveActions) {
                      feature.correctiveActions = [];
                    }
                    if (
                      this.config.correctiveFeatureCodes.includes(
                        feature.featureCode,
                      )
                    ) {
                      return (
                        <div key={index}>
                          <CorrectiveActions
                            disabled={disabled}
                            key={index}
                            feature={feature}
                            correctivePoliciesList={correctivePoliciesList}
                            callback={featureData =>
                              this.setUpdatedFeatureData(index, featureData)
                            }
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            {policyData && !policyData.policyPayloadVersion && (
              <Form.Item
                name="correctiveActions"
                label={
                  <span>
                    {t('form_SelectCorrectivePolicy')}&nbsp;
                    <Tooltip
                      title={t('correctivePolicy_descriptionTxt')}
                      placement="right"
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <Select style={{ width: '100%' }} disabled={disabled}>
                  <Option value="NONE">{t('label_none')}</Option>
                  {this.state.correctivePoliciesList}
                </Select>
              </Form.Item>
            )}
          </div>

          {!disabled && (
            <Col span={16} offset={18}>
              <div className={styles.buttons}>
                <Form.Item>
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={this.props.setPrevStep}
                  >
                    {t('label_back')}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t('label_continue')}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          )}
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(SelectPolicyType));

/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Badge,
  Col,
  Result,
  Row,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import flow_layout from '../../../../../public/images/floor_plan.png';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      breadcrumbName: t('home_menu_factory'),
    },
    {
      breadcrumbName: t('home_menu_flow_layout'),
    },
  ];
};

const imageContainerStyle = {
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
};

const imageStyle = {
  height: '500px',
  objectFit: 'contain',
};

const areaStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: '5px'
};

class Flowlayout extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const { t } = this.props;
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.state = {
      devices: null,
      dataSource: [
        { name: t('label_machine'), value: "" },
        { name: t('label_status_commStatus'), value: "" },
        { name: t('label_status_lastActiveAt'), value: "" },
        { name: t('label_status_operatorName'), value: "" },
        { name: t('label_status_productName'), value: "" },
      ],
      showTooltip: false,
    };
  }

  handleMouseLeave = () => {
    this.setState({ showTooltip: false });
  };

  componentDidMount() {
    this.context.setCurrentRoute('allFactoryFlowLayoutsPenAssembly');
    this.getDeviceFilters();
  }

  handleClick = (machine) => {
    const newTab = window.open(`${window.location.pathname}/../../devices/penassembly/${machine}/statistics`, '_blank');
    newTab.focus();
  };

  handleMouseEnter = (t, label, properties) => {
    const { dataSource } = this.state;
    const updatedDataSource = dataSource.map(item => ({ ...item, value: 'N/A' }));
    const labelMachineItemIndex = updatedDataSource.findIndex(item => item.name === t('label_machine'));
    if (labelMachineItemIndex !== -1) {
      updatedDataSource[labelMachineItemIndex].value = label;
    }

    if (properties && properties.length > 0) {
      updatedDataSource.forEach(item => {
        if (item.name === t('label_status_commStatus')) {
          item.value = properties.find(prop => prop.name === '_status_commStatus')?.value || 'N/A';
        }
        if (item.name === t('label_status_lastActiveAt')) {
          const lastActiveAt = properties.find(prop => prop.name === '_status_lastActiveAt')?.value;
          item.value = lastActiveAt ? new Date(parseInt(lastActiveAt)).toLocaleString() : 'N/A';
        }
        if (item.name === t('label_status_operatorName')) {
          item.value = properties.find(prop => prop.name === '_status_operatorName')?.value || 'N/A';
        }
        if (item.name === t('label_status_productName')) {
          item.value = properties.find(prop => prop.name === '_status_productName')?.value || 'N/A';
        }
      });
    }

    this.setState({ showTooltip: true, dataSource: updatedDataSource });
  };

  getDeviceFilters = () => {
    const config = this.props.context;
    const { t } = this.props;

    axios
      .get(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/devices?requireDeviceInfo=true',
      )
      .then(res => {
        if (res.status === 200) {
          const devices = res.data.data;
          this.setState({ devices: devices.devices });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deviceFilterRetrieveError'), t);
      });
  };

  render() {
    const config = this.props.context;
    const { t } = this.props;
    const { dataSource, devices, showTooltip } = this.state;
    const devicesList = [
      { id: 'pa06', top: '15px', left: 'calc(50% - 39px)' },
      { id: 'pa07', top: '58px', left: 'calc(50% - 32px)' },
      { id: 'pa05', top: '114px', left: 'calc(50% - 32px)' },
      { id: 'pa09', top: '156px', left: 'calc(50% - 51px)' },
      { id: 'pa04', top: '156px', left: 'calc(50% + 188px)' },
      { id: 'pa08', top: '228px', left: 'calc(50% - 50px)' },
      { id: 'pa02', top: '307px', left: 'calc(50% - 18px)' },
      { id: 'pa01', top: '349px', left: 'calc(50% - 18px)' },
      { id: 'pa03', top: '401px', left: 'calc(50% + 20px)' },
    ];

    const DevicesListWithProperties = devicesList.map(device => {
      const foundDevice = devices?.find(d => d.deviceIdentifier.toLowerCase() === device.id.toLowerCase());
      const status = foundDevice ? foundDevice.properties.find(property => property.name === '_status_commStatus')?.value : 'NOT-FOUND';
      const deviceWithProperties = foundDevice
        ? { ...device, properties: foundDevice.properties, status: status, color: status === 'ACTIVE' ? 'green' : status === 'REMOVED' ? 'red' : 'gray', }
        : device;
      return deviceWithProperties;
    });
    const deviceElements = DevicesListWithProperties.map((device) => (
      <div
        key={device.id}
        className={styles.hoverDiv}
        style={{ ...areaStyle, cursor: 'pointer', top: device.top, left: device.left, width: '52px', height: '26px' }}
        onClick={() => this.handleClick(device.id)}
        onMouseEnter={() => this.handleMouseEnter(t, device.id.toUpperCase(), device.properties)}
        onMouseLeave={this.handleMouseLeave}
        title={device.properties && device.status}
      >
        <span style={{ marginRight: '4px' }}>{device.id.toUpperCase()}</span>
        <Badge color={device.properties && device.color} />
      </div>
    ));

    return (
      <div>
        <Authorized
          scope={['perm:devices:view']}
          yes={
            <div>
              <PageHeader
                className={styles.pageHeader}
                breadcrumb={
                  <BreadcrumbComponent
                    breadcrumbList={routes(config.appName, t)}
                  />
                }
              >
              </PageHeader>
              <div className={styles.table}>{' '}</div>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={imageContainerStyle}>
                    {showTooltip && (
                      <div className={styles.tooltip}>
                        {dataSource.map((item, index) => (
                          <div key={`${item.name}_${index}`}>
                            {item.name === t('label_machine') ? (
                              <span>
                                {`${item.value} (${dataSource.find((i) => i.name === t('label_status_commStatus')).value} - 
                                ${dataSource.find((i) => i.name === t('label_status_lastActiveAt')).value})`}
                              </span>
                            ) : (
                              item.name !== t('label_status_commStatus') && item.name !== t('label_status_lastActiveAt') && (
                                <>
                                  <span>{`${item.name}: `}</span>
                                  <span>{item.value}</span>
                                </>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    <img
                      src={flow_layout}
                      alt="flow layout"
                      style={imageStyle}
                    />
                    {deviceElements}
                  </div>
                </Col>
              </Row>
            </div>
          }
          no={
            <Result
              status="403"
              title={t('noPerm_accessPageTitle')}
              subTitle={t('noPerm_contactSysAdmin')}
            />
          }
        />
      </div>
    );
  }
}

Flowlayout.contextType = RoutesContext;
export default withConfigContext(withTranslation()(Flowlayout));

/*
 * Copyright (C) 2022. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useEffect, useState } from 'react';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import { Alert, Drawer, Tabs } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import DeviceOperations from '../../../../../../components/DeviceOperations';
import axios from 'axios';
import styles from './styles.module.css';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';

const DeviceOperationsDrawer = props => {
  const config = props.context;
  const { t } = props;

  const [deviceType, setDeviceType] = useState('android');
  const [hasAndroid, setHasAndroid] = useState(false);
  const [hasIos, setHasIos] = useState(false);
  const [hasWindwos, setHasWindwos] = useState(false);
  const ownerships = ['COPE', 'BYOD'];
  const defaultPlatformIcons = config.defaultPlatformIcons;

  useEffect(() => {
    hasDeviceTypesInGroups();
  }, []);

  const hasDeviceTypesInGroups = () => {
    const identifiers = props.groupIDList;
    axios
      .post(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/groups/device-types',
        identifiers,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          setHasAndroid(res.data.data.hasAndroid);
          setHasIos(res.data.data.hasIos);
          setHasWindwos(res.data.data.hasWindows);
        }
      })
      .catch(error => {
        handleApiError(error, t('label_error_retrieve_operation'), t);
      });
  };

  const onClickTab = key => {
    setDeviceType(key);
  };

  let className = '';
  const hasOperations = () => {
    if (deviceType === 'android') {
      return (
        <div className={styles.alert}>
          {!hasAndroid
            ? ((className = styles.disableClick),
              (
                <Alert
                  message={t('label_add_android_devices_msg')}
                  type="warning"
                  closable={false}
                />
              ))
            : null}
        </div>
      );
    } else if (deviceType === 'ios') {
      return (
        <div className={styles.alert}>
          {!hasIos
            ? ((className = styles.disableClick),
              (
                <Alert
                  message={t('label_add_ios_devices_msg')}
                  type="warning"
                  closable={false}
                />
              ))
            : null}
        </div>
      );
    } else if (deviceType === 'windows') {
      return (
        <div className={styles.alert}>
          {!hasWindwos
            ? ((className = styles.disableClick),
              (
                <Alert
                  message={t('label_add_windows_devices_msg')}
                  type="warning"
                  closable={false}
                />
              ))
            : null}
        </div>
      );
    }
  };

  return (
    <div>
      <Drawer
        title={t('label_operations')}
        placement="right"
        closable={true}
        onClose={props.onOperationsClose}
        visible={true}
        width={380}
      >
        <Tabs
          defaultActiveKey="1"
          onTabClick={onClickTab}
          className={styles.tabs}
          items={[
            {
              label: (
                <span
                  style={{
                    marginRight: 6,
                    fontSize: 15,
                    color: defaultPlatformIcons.android.color,
                    textAlign: 'center',
                  }}
                >
                  <LegacyIcon
                    type={defaultPlatformIcons.android.icon}
                    theme={defaultPlatformIcons.android.theme}
                  />
                  Android
                </span>
              ),
              key: 'android',
            },
            {
              label: (
                <span
                  style={{
                    marginRight: 6,
                    fontSize: 15,
                    color: defaultPlatformIcons.ios.color,
                    textAlign: 'center',
                  }}
                >
                  <LegacyIcon
                    type={defaultPlatformIcons.ios.icon}
                    theme={defaultPlatformIcons.ios.theme}
                  />
                  iOS
                </span>
              ),
              key: 'ios',
            },
            {
              label: (
                <span
                  style={{
                    fontSize: 15,
                    color: defaultPlatformIcons.windows.color,
                    textAlign: 'center',
                  }}
                >
                  <LegacyIcon
                    type={defaultPlatformIcons.windows.icon}
                    theme={defaultPlatformIcons.windows.theme}
                  />
                  Windows
                </span>
              ),
              key: 'windows',
            },
          ]}
        />

        <div className={styles.container}>
          {hasOperations()}
          <div className={className}>
            <DeviceOperations
              deviceType={deviceType}
              ownerships={ownerships}
              groupIDList={props.groupIDList}
              idType={'group'}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default withConfigContext(withTranslation()(DeviceOperationsDrawer));

/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Select } from 'antd';
import Twemoji from 'react-twemoji';
import { supportedLocals } from '../../services/internationalization/i18n';
import { withLocaleContext } from '../LocaleContext';
import './style.css';

const { Option } = Select;

class LocaleChooser extends React.Component {
  handleChange = value => {
    this.props.context.changeLocale(value);
  };

  render() {
    return (
      <Select
        size="small"
        className="language-selector"
        style={{ width: 120, marginTop: 20 }}
        onChange={this.handleChange}
        value={this.props.context.currentLocale}
      >
        {Object.keys(supportedLocals).map(key => {
          const locale = supportedLocals[key];
          return (
            <Option key={key} value={key}>
              <Twemoji options={{ className: 'twemoji' }}>
                {locale.displayText}
              </Twemoji>
            </Option>
          );
        })}
      </Select>
    );
  }
}

export default withLocaleContext(LocaleChooser);

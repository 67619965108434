/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { Fragment } from 'react';
import styles from './styles.module.css';
import {
  Alert,
  Spin,
  Button,
  Result,
  Table,
  Tooltip,
  Col,
  Row,
  Typography,
  Tag,
} from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import AddGeoFence from './component/AddGeoFence';
import GeoFencesMap from './component/GeoFencesMap';
import { Link } from 'react-router-dom';
import { Circle, Marker, TileLayer, Polygon } from 'react-leaflet';
import GeoFenceActions from './component/GeoFenceActions';
import L from 'leaflet';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;

// Icons for the location markers
const geoFenceMarker = new L.Icon({
  iconUrl: require('./geo-fence.svg'),
  iconRetinaUrl: require('./geo-fence.svg'),
  shadowAnchor: [22, 22],
  popupAnchor: [0, -22],
  iconSize: [50, 50],
  tooltipAnchor: [0, -22],
});

class GeoFencesTable extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: true,
      isAllNamesVisible: false,
      groups: [],
    };
  }

  componentDidMount() {
    this.fetchGeoFenceData();
    this.fetchGroups();
  }

  // Rerender component when parameters change
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)
    ) {
      const pagination = { ...this.state.pagination };
      pagination.page = 1;
      pagination.current = 1;
      this.setState({ pagination });
      this.fetchGeoFenceData(true, {}, this.props.filters);
    }
  }

  // get Geo fence data
  fetchGeoFenceData = (params = {}, filters = {}) => {
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;
    const { t } = this.props;
    let limit = 10;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: limit,
      requireEventData: true,
      ...this.props.filters,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    axios
      .get(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/geo-services/geo-fence?' +
        encodedExtraParams,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        const pagination = { ...this.state.pagination };
        if (res.status === 200) {
          let data = res.data.data.data;
          data.map((fence, i) => {
            fence.key = i;
          });
          this.setState({
            data,
            loading: false,
            pagination,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_loadGeoFenceDataError'), t);
        this.setState({ loading: false });
      });
  };

  // fetch data from api
  fetchGroups = () => {
    const config = this.props.context;
    const { t } = this.props;
    this.setState({ loading: true });

    let apiUrl;
    if (isAuthorized(config.scopes, ['perm:admin-groups:view'])) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/admin/groups';
    } else if (isAuthorized(config.scopes, ['perm:groups:groups'])) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/groups';
    } else {
      return;
    }

    apiUrl += '?offset=0&limit=99';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            groups: res.data.data.deviceGroups,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_deviceGroups') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  viewMap = geoFence => {
    let center;
    if (geoFence.fenceShape === 'polygon') {
      const geoCoordinates = JSON.parse(geoFence.geoJson);
      center = [geoCoordinates[0][0].lat, geoCoordinates[0][0].lng];
    } else {
      center = [geoFence.latitude, geoFence.longitude];
    }

    const attribution = this.config.geoMap.attribution;
    const url = this.config.geoMap.url;
    return (
      <Map
        ref={this.customMap}
        center={center}
        zoom={15}
        style={{ height: 400, width: 600 }}
      >
        <TileLayer url={url} attribution={attribution} />
        <Fragment>
          {geoFence.fenceShape === 'circle' && (
            <Marker
              position={{ lat: geoFence.latitude, lng: geoFence.longitude }}
              icon={geoFenceMarker}
            >
              <Circle
                center={{ lat: geoFence.latitude, lng: geoFence.longitude }}
                radius={geoFence.radius}
              />
            </Marker>
          )}
          {geoFence.fenceShape === 'polygon' && (
            <Polygon positions={JSON.parse(geoFence.geoJson)} />
          )}
        </Fragment>
      </Map>
    );
  };

  onShowNames = () => {
    this.setState({ isAllNamesVisible: true });
  };

  onHideNames = () => {
    this.setState({ isAllNamesVisible: false });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchGeoFenceData({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  render() {
    const { loading, data, isAllNamesVisible, groups } = this.state;
    const { viewType, t } = this.props;
    const columns = [
      {
        title: t('label_fenceName'),
        dataIndex: 'fenceName',
      },
      {
        title: t('label_description'),
        dataIndex: 'description',
      },
      {
        title: t('home_menu_groups'),
        dataIndex: 'groupNames',
        render: (data, row) => {
          let groupsName = [];
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              groupsName.push(
                <Tag key={key}>
                  <a href={`/${this.config.appName}/groups?name=${data[key]}`}>
                    {data[key]}
                  </a>
                </Tag>,
              );
            }
          }
          return <Row>{groupsName}</Row>;
        },
      },
      {
        title: t('label_events'),
        dataIndex: 'eventConfig',
        render: (data, row) => {
          const { eventConfig } = row;
          const colors = ['#6ab04c', '#9b59b6', '#3498db'];
          if (eventConfig) {
            return (
              <Row>
                {eventConfig.map((event, index) => {
                  return (
                    <Tag color={colors[index]} key={index}>
                      {t(`label_${event.eventLogic.toLowerCase()}_uppercase`)}
                    </Tag>
                  );
                })}{' '}
              </Row>
            );
          }
          return '';
        },
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (id, row) => (
          <GeoFenceActions
            data={row}
            fetchGeoFenceData={this.fetchGeoFenceData}
            groups={groups}
          />
        ),
      },
    ];

    return !loading ? (
      <div>
        <div style={{ background: '#f0f2f5' }}>
          <AddGeoFence
            fetchGeoFenceData={this.fetchGeoFenceData}
            style={{ marginBottom: '10px' }}
            groups={groups}
          />
        </div>
        <div>
          {viewType === 'listView' && data.length > 0 && (
            <div className={styles.tableContainer}>
              {!isAuthorized(this.config.scopes, [
                ['perm:admin-groups:view', 'perm:groups:groups'],
              ]) && (
                  <div>
                    <HtmlComments
                      permission={
                        '/permission/admin/device-mgt/admin/groups/view OR\n' +
                        '/permission/admin/device-mgt/groups/view'
                      }
                    />
                    <Alert message={t('noPerm_viewGroups')} banner closable />
                  </div>
                )}
              <Table
                loading={loading}
                expandable={{
                  expandedRowRender: record => (
                    <span>
                      <div
                        style={{
                          display: 'block',
                          marginLeft: '10%',
                        }}
                      >
                        {record.fenceShape === 'circle' && (
                          <Row>
                            <Col span={6} offset={3}>
                              <Text strong>{t('label_latitude')} : </Text>
                              <Text>{record.latitude}</Text>
                            </Col>
                            <Col span={6}>
                              <Text strong>{t('label_longitude')} : </Text>
                              <Text>{record.longitude}</Text>
                            </Col>
                            <Col span={6}>
                              <Text strong>{t('label_radius')} : </Text>
                              <Text>{record.radius}</Text>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <div
                            style={{
                              display: 'block',
                              marginLeft: '20%',
                              marginTop: 10,
                            }}
                          >
                            {this.viewMap(record)}
                          </div>
                        </Row>
                      </div>
                    </span>
                  ),
                  rowExpandable: record => record.name !== 'Not Expandable',
                  expandIconColumnIndex: -1,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <Tooltip placement="top" title={t('label_cancel')}>
                        <a>
                          <EyeInvisibleOutlined
                            onClick={e => onExpand(record, e)}
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <Tooltip placement="top" title={t('"label_view"')}>
                        <a>
                          <EyeOutlined onClick={e => onExpand(record, e)} />
                        </a>
                      </Tooltip>
                    ),
                }}
                dataSource={data}
                columns={columns}
                pagination={{ position: 'bottomRight', disabled: loading }}
              />
            </div>
          )}
          {viewType === 'mapView' && data.length > 0 && (
            <div>
              <Button
                className={styles.customButton}
                onClick={
                  isAllNamesVisible ? this.onHideNames : this.onShowNames
                }
              >
                {!isAllNamesVisible ? (
                  <span>
                    <EyeOutlined /> {t('label_showAllNames')}
                  </span>
                ) : (
                  <span>
                    <EyeInvisibleOutlined /> {t('label_hideAllNames')}
                  </span>
                )}
              </Button>
              <div className={styles.tableContainer}>
                <GeoFencesMap
                  data={data}
                  groups={groups}
                  fetchGeoFenceData={this.fetchGeoFenceData}
                  isAllNamesVisible={isAllNamesVisible}
                />
              </div>
            </div>
          )}
        </div>
        {data.length === 0 && (
          <Result
            status="404"
            title={t('noGeoFence_Txt')}
            subTitle={t('addNewGeoFence_Txt')}
            extra={
              <Link
                to={`/${this.config.appName}/geo-fences?add-new-geo-fence=true`}
              >
                <Button type="primary">{t('label_createNewGeoFence')}</Button>
              </Link>
            }
          />
        )}
      </div>
    ) : (
      <div className={styles.spinContainer}>
        <Spin />
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GeoFencesTable));

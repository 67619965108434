/* eslint-disable no-nested-ternary */
/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext } from 'react';
import { handleApiError } from '../../../../services/utils/errorHandler';
import {
  Col,
  Result,
  Row,
  Divider,
  Select,
  Space,
  Spin
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import axios from 'axios';
import { subHours } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import { getUnixTime } from '../../../../services/utils/functions';
import configs from '../../../../../public/conf/config.json';
import DateRangeDropdown from '../../components/common/DateRangeDropdown';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';

const MonthlyPowerConsumptionReports = props => {
  const config = props.context;
  const { t } = props;
  const routesContext = useContext(RoutesContext);
  const [meterSerials, setMeterSerials] = useState('var-serial=000000000013');
  const rightNow = new Date();
  const [isLoading, setIsLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [count, setCount] = useState(0);
  const [options, setOptions] = useState([
    { value: '000000000013', label: 'CEB 2' }
  ]);
  const [selectedOption, setSelectedOption] = useState('000000000013');
  const [dashboardUrl, setDashboardUrl] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const paramSerial = urlParams.get('serial');
  const startTimestamp = urlParams.get('startTimestamp');
  const endTimestamp = urlParams.get('endTimestamp');
  const initialState =
    startTimestamp && endTimestamp
      ? {
          from: parseInt(startTimestamp, 10),
          to: parseInt(endTimestamp, 10)
        }
      : {
          from: getUnixTime(subHours(rightNow, 6)),
          to: getUnixTime(rightNow)
        };

  const [range, setRange] = useState(initialState);

  useEffect(() => {
    routesContext.setCurrentRoute('allMonthlyPowerConsumptionAnalytics');
    if (meterSerials) {
      setIsLoading(true);
      setDashboardUrl(
        // eslint-disable-next-line max-len
        `${configs.grafanaConfig.monthlyPowerConsumptionAnalyticsReportsVisualization}&${meterSerials}&from=${range.from}&to=${range.to}`
      );
    }
  }, [meterSerials, range.from, range.to, dashboardUrl]);

  const handleSerialChange = serial => {
    if (serial.length !== 0) {
      setSelectedOption(serial);
      const serialString = `var-serial=${serial}`;
      setMeterSerials(serialString);
    }
  };

  useEffect(() => {
    getMeters();
  }, []);

  const getMeters = (params = {}, filters = '') => {
    setIsLoading(true);
    // todo : pagination to be added if needed in future
    const currentPage = 1;

    let limit = 20;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: limit * (currentPage - 1),
      limit: limit
    };

    let encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    if (filters !== '') {
      encodedExtraParams = encodedExtraParams + filters;
    }
    axios
      .get(
        window.location.origin +
            configs.serverConfig.invoker.uri +
            configs.serverConfig.invoker.meterMgt +
          '/devices?requireDeviceInfo=true&type=power-meter&' +
          encodedExtraParams
      )
      .then(res => {
        const metersData = res.data.data.devices
          .map(device => ({
            serial: device.deviceIdentifier,
            name: device.name,
            branch:
              device.properties?.find(prop => prop.name === 'BRANCH')?.value ||
              '-',
            state: device.enrolmentInfo.status,
            subTypeId: device.properties?.find(
              prop => prop.name === 'SUB_TYPE_ID'
            )?.value
          }))
          .filter(device => device.subTypeId !== '103');

        // metersData now contains devices without SUB_TYPE_ID value equal to "103"

        setDevices(metersData);
        setCount(res.data.data.count);
        setIsLoading(false);

        const updatedOptions = [
          ...metersData.map(device => ({
            label: device.name,
            value: device.serial
          }))
        ];

        setOptions(updatedOptions);
      })
      .catch(error => {
        handleApiError(error, t('api_getMeterError'), t);
        setIsLoading(false);
      });
  };

  const routes = appName => [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home')
    },
    {
      breadcrumbName: t('home_menu_monthlyPowerConsumptionAnalytics')
    }
  ];

  return (
    <div>
      <HtmlComments
        permission={
          '/permission/admin/device-mgt/rmr/device/admin/view-meter-reading'
        }
      />
      <Authorized
        scope={['perm:rmr:admin:view-meter-reading']}
        yes={
          <div>
            <PageHeader className={styles.pageHeader} ghost={false}>
              <div className={styles.positioning}>
                <Row justify="space-between" align="middle" gutter={8}>
                  <Col>
                    <BreadcrumbComponent
                      breadcrumbList={routes(config.appName)}
                    />
                  </Col>
                  <Col>
                    <Row justify="space-between" align="middle" gutter={[8, 8]}>
                      <Col>
                        <Space>
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.props.label
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.props.value
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{
                              width: '100%',
                              minWidth: '200px'
                            }}
                            placeholder={t('please_select')}
                            defaultValue={'000000000013'}
                            value={selectedOption}
                            onChange={handleSerialChange}
                            options={options}
                          />
                        </Space>
                      </Col>
                      <Col>
                        <Space>
                          {startTimestamp && endTimestamp ? (
                            <DateRangeDropdown
                              startTimestamp={range.from}
                              endTimestamp={range.to}
                              getRange={data => setRange(data)}
                              closeOnBackground
                              disabled={true}
                              range={11}
                            />
                          ) : (
                            <DateRangeDropdown
                              alignDropdown="right"
                              getRange={data => setRange(data)}
                              closeOnBackground
                              range={11}
                            />
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </PageHeader>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {dashboardUrl !== '' &&
                  meterSerials !== '' &&
                  range.from &&
                  range.to && (
                    <div className="row">
                      <div className="col-md-12">
                        <IframeWrapper
                          iframeSrc={dashboardUrl}
                          height="404px"
                        />
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
            <Divider />
          </div>
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
};

export default withConfigContext(
  withTranslation()(MonthlyPowerConsumptionReports)
);

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button, Select, Input, Form, Divider, Alert } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

class UserForm extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isModalVisible: false,
      userStores: [],
      selectedUserStore: 'PRIMARY',
      roles: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:roles:view'])) {
      this.getRole(this.state.selectedUserStore);
    }
    if (isAuthorized(this.config.scopes, ['perm:users:user-details'])) {
      this.getUserStores();
    }
    if (this.props.values) {
      this.formRef.current.setFieldsValue(this.props.values);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-user');
    if (JSON.parse(name)) {
      this.formRef.current.resetFields();
    }
  }

  onFinish = values => {
    this.props.callback(values);
  };

  getUserStores = () => {
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/users/user-stores';

    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.setState({ userStores: res.data.data.userStores });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
      });
  };

  getRole = userStore => {
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/roles?user-store=' +
      userStore +
      '&limit=100';

    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.setState({ roles: res.data.data.roles });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_roles') }),
          t,
        );
      });
  };

  handleUserStoreChange = value => {
    this.setState({ selectedUserStore: value });
    this.getRole(value);
  };

  render() {
    const { roles, selectedUserStore, userStores, loading } = this.state;
    const { action } = this.props;
    const { t } = this.props;
    const roleOptions = roles.map(role => (
      <Select.Option key={role} value={role}>
        {role}
      </Select.Option>
    ));
    const userStoreOptions = userStores.map(userStore => (
      <Select.Option key={userStore} value={userStore}>
        {userStore}
      </Select.Option>
    ));
    return (
      <div>
        <Divider>
          <PageHeader
            className="site-page-header-responsive"
            subTitle={t('title_updateUserDetails')}
          />
        </Divider>
        <Form
          {...formItemLayout}
          name="addUser"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          {!isAuthorized(this.config.scopes, ['perm:roles:view']) && (
            <div>
              <HtmlComments
                permission={'/permission/admin/device-mgt/roles/view'}
              />
              <Alert
                message={t('noPerm_someFields')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}
          <Form.Item
            name="userStoreDomain"
            label={t('label_userStoreDomain')}
            initialValue={selectedUserStore}
          >
            <Select
              disabled={
                !isAuthorized(this.config.scopes, [
                  'perm:users:user-details',
                ]) || action === 'Update'
              }
              onChange={this.handleUserStoreChange}
            >
              {userStoreOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="firstname"
            label={t('label_firstName')}
            rules={[
              {
                required: true,
                message: t('form_firstNameRequiredMsg'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastname"
            label={t('label_lastName')}
            rules={[{ required: true, message: t('form_lastNameRequiredMsg') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label={t('label_username')}
            rules={[{ required: true, message: t('form_usernameRequiredMsg') }]}
            extra={action === 'Add' && t('form_usernameNotValidMsg')}
          >
            <Input disabled={action === 'Update'} />
          </Form.Item>
          <Form.Item
            name="emailAddress"
            label={t('label_email')}
            rules={[
              {
                type: 'email',
                message: t('form_emailNotValidMsg'),
              },
              {
                required: true,
                message: t('form_emailRequiredMsg'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {action === 'Add' && (
            <div>
              <Form.Item
                name="password"
                label={t('label_password')}
                rules={[
                  {
                    required: true,
                    message: t('form_passwordRequiredMsg'),
                  },
                  {
                    pattern: new RegExp('^(((?!(\\s)).){5,})*$'),
                    message: t('form_passwordNotValidMsg'),
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label={t('label_confirmPassword')}
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t('form_confirmPasswordRequiredMsg'),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('form_passwordNotMatchMsg'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </div>
          )}
          <HtmlComments
            permission={'/permission/admin/device-mgt/roles/view'}
          />
          <Form.Item
            name="userRoles"
            label={t('label_userRoles')}
            rules={[
              {
                required: true,
                message: t('form_userRoleRequiredMsg'),
              },
            ]}
          >
            <Select
              mode={'multiple'}
              placeholder={t('form_searchUserRoles')}
              disabled={!isAuthorized(this.config.scopes, ['perm:roles:view'])}
            >
              {roleOptions}
            </Select>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              disabled={!isAuthorized(this.config.scopes, ['perm:roles:view'])}
            >
              <HtmlComments
                permission={'/permission/admin/device-mgt/roles/view'}
              />
              {t(`label_${action.toLowerCase()}`)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(UserForm));

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import {
  DesktopOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Card, Col, message, Modal, notification, Row, Spin } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import AddDeviceType from '../AddDeviceType';
import UpdateDeviceType from '../AddDeviceType/components/AddDeviceTypeForm/components/UpdateDeviceType';

let apiUrl;

class Index extends React.Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      selectedData: null,
      isModalVisible: false,
    };
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleSettingsClick = (data) => {
    this.setState({ selectedData: data, isModalVisible: true });
  };

  handleModalClose = () => {
    this.setState({ isModalVisible: false, selectedData: null });
  };

  componentDidMount() {
    this.fetchDeviceTypes();
  }

  // fetch data from api
  fetchDeviceTypes = (params = {}) => {
    const config = this.props.context;
    const { t } = this.props;
    this.setState({ loading: true });

    apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      config.serverConfig.invoker.deviceMgt +
      '/device-types';

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          //remove sim from device types
          let data = res.data.data.filter(deviceType => deviceType.name !== "sim");

          this.setState({
            loading: false,
            data,
            pagination,
          });
        }
      })
      .catch(error => {
        if (error.hasOwnProperty('response') && error.response.status === 401) {
          // todo display a popop with error
          message.error(t('api_notLoggedInError'));
          window.location.href =
            window.location.origin + `/${config.appName}/login`;
        } else {
          notification.error({
            message: t('api_errorMsg'),
            duration: 0,
            description: t('api_loadError', { label: t('label_machineTypes') }),
          });
        }

        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  render() {
    const { data, loading, isModalVisible, selectedData } = this.state;
    const { Meta } = Card;
    const { t } = this.props;
    const itemCard = data.map((data) => (
      <Col xs={12} sm={12} md={8} lg={6} xl={6} key={data.id}>
        <Card
          size="default"
          style={{
            borderRadius: 10,
            marginRight: 24,
            marginTop: 10,
            background: '#f5f5f5',
            width: '100%',
          }}
          hoverable
          bordered={true}
          actions={[
            <SettingOutlined key="setting" onClick={() => this.handleSettingsClick(data)} />,
            "",
          ]}
        >
          <Meta
            avatar={<DesktopOutlined key="device-types" />}
            title={data.name}
          />
        </Card>
      </Col>
    ));

    return (
      <div style={{ background: '#fff', padding: '20px' }}>
        {loading && <Spin />}
        <Row gutter={[16, 16]}>{itemCard}</Row>
        <AddDeviceType />

        {selectedData && (
          <Modal
            title={`${t('label_update_machine_title')}: ${selectedData.name}`}
            open={isModalVisible}
            onCancel={this.handleModalClose}
            footer={null}
            maskClosable={false}
            width={700}
          >
            <UpdateDeviceType
              modalVisibility={isModalVisible}
              selectedData={selectedData}
              closeModalCallback={this.handleModalClose} />
          </Modal>
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(Index));

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Modal, notification } from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import GroupForm from '../GroupForm';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class AddGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalVisible: false,
    };
  }

  componentDidMount() {
    this.changeModalVisibility();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.addModalVisible === false) {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility = () => {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-group');
    if (JSON.parse(name)) {
      this.setState({
        addModalVisible: true,
      });
    }
  };

  addGroup = values => {
    const config = this.props.context;
    const { t } = this.props;
    let apiUrl;
    const groupData = {};
    groupData.name = values.name.trim();
    groupData.description = values.description;
    groupData.owner = this.props.context.user;

    if (isAuthorized(config.scopes, ['perm:admin-groups:add'])) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/admin/groups';
    } else if (isAuthorized(config.scopes, ['perm:groups:add'])) {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/groups';
    }

    // send request to the invoker
    axios
      .post(apiUrl, groupData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 201) {
          this.props.fetchGroups();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_addGroupMsg'),
          });
          this.hideGroupAddingModal();
        }
      })
      .catch(error => {
        if (error.response.status === 409) {
          handleApiError(error, error.response.data.data.replace('.', ''), t);
        } else {
          handleApiError(
            error,
            t('api_addError', { label: t('home_menu_group') }),
            t,
          );
        }
      });
  };

  hideGroupAddingModal = () => {
    window.history.pushState(null, '', location.href.split('?')[0]);
    this.setState({
      addModalVisible: false,
    });
  };

  fetchNewGroupData = values => {
    this.addGroup(values);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Modal
            title={t('title_addNewGroup')}
            open={this.state.addModalVisible}
            onCancel={this.hideGroupAddingModal}
            footer={null}
            maskClosable={false}
          >
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/admin/groups/add OR\n' +
                '/permission/admin/device-mgt/groups/add'
              }
            />
            <GroupForm
              action={'Add'}
              modalVisibility={this.state.addModalVisible}
              callback={values => this.fetchNewGroupData(values)}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AddGroup));

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Collapse } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import DeviceTable from '../../../../../../components/DevicesTable';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import Filter from '../../../../../../components/Filter';
import { SearchOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

const { Panel } = Collapse;

const emptyDevicePage = t => {
  return {
    title: t('noAssignDevicesToGroup_Txt'),
    subTitle: t('assignEnrolledDevicesToGroup_Txt'),
    buttonName: t('label_AssignDevicesToGroup'),
    url: '/devices',
  };
};

const setSearchFields = t => {
  return [
    {
      name: 'name',
      type: 'input',
      placeholder: t('label_deviceName'),
      label: t('label_name'),
    },
    {
      name: 'user',
      type: 'input',
      placeholder: t('label_owner'),
      label: t('home_menu_user'),
    },
    {
      name: 'serialNumber',
      type: 'input',
      placeholder: t('label_serialNumber'),
      label: t('label_serialNumber'),
    },
    {
      name: 'status',
      placeholder: t('label_status'),
      type: 'select',
      values: ['All'],
      label: t('label_status'),
    },
    {
      name: 'type',
      placeholder: t('label_deviceType'),
      type: 'select',
      values: ['All'],
      label: t('label_type'),
    },
    {
      name: 'ownership',
      placeholder: t('label_ownership'),
      type: 'select',
      values: ['All'],
      label: t('label_ownership'),
    },
  ];
};

class AlreadyAssignedDevices extends React.Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    const config = this.props.context;
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      visible: false,
      apiUrl:
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/devices?',
      filterFields: [],
      filters: {},
      loadSearchBar: true,
    };
  }

  componentDidMount() {
    this.getDeviceFilters();
  }

  getDeviceFilters = () => {
    const config = this.props.context;
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/devices/filters',
      )
      .then(res => {
        if (res.status === 200) {
          const deviceFilters = res.data.data;
          let searchFields = setSearchFields(t);
          searchFields.map(searchField => {
            if (searchField.name === 'status') {
              searchField.values.push(...deviceFilters.statuses);
            }
            if (searchField.name === 'type') {
              searchField.values.push(...deviceFilters.deviceTypes);
            }
            if (searchField.name === 'ownership') {
              searchField.values.push(...deviceFilters.ownerships);
            }
          });
          this.setState({ filterFields: searchFields });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deviceFilterRetrieveError'), t);
      });
  };

  fetchFilterData = (params, values) => {
    this.setState({ filters: values });
  };

  fetchSearchBarStatus = status => {
    this.setState({ loadSearchBar: status });
  };

  onAssignDevicesCallback = () => {
    this.props.assignDevicesCallback();
  };

  render() {
    const { apiUrl, loadSearchBar } = this.state;
    const { t } = this.props;
    return (
      <div style={{ alignItems: 'center' }}>
        {loadSearchBar && (
          <div style={{ marginBottom: 10 }}>
            <Collapse
              bordered={false}
              expandIcon={() => <SearchOutlined />}
              className={styles.searchCollapse}
            >
              <Panel
                header={t('form_searchDevices')}
                key="1"
                style={{ borderColor: '#fff' }}
              >
                <Filter
                  filters={this.state.filterFields}
                  callback={(params, values) =>
                    this.fetchFilterData({}, values)
                  }
                />
              </Panel>
            </Collapse>
          </div>
        )}

        <DeviceTable
          filters={this.state.filters}
          showAdvancedSearch={false}
          apiUrl={apiUrl}
          isGroupRemoverEnable={true}
          groupId={this.props.groupData.id}
          searchBarCallback={status => this.fetchSearchBarStatus(status)}
          emptyDevicePage={emptyDevicePage(t)}
          refreshTable={this.props.refreshTable}
          assignDevicesCallback={this.onAssignDevicesCallback}
        />
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AlreadyAssignedDevices));

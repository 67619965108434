/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';

import {
  BellOutlined,
  BookOutlined,
  AppstoreOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  AimOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  LineChartOutlined,
  PieChartOutlined,
  ThunderboltOutlined,
  FundFilled,
  PieChartFilled,
  PieChartTwoTone,
  SafetyCertificateOutlined,
  ControlOutlined,
  GoldOutlined,
  TeamOutlined,
  EditOutlined,
  LayoutOutlined,
  FieldTimeOutlined,
  CarryOutOutlined,
  TableOutlined,
  FileSearchOutlined,
  HddOutlined,
  RiseOutlined,
  ProjectOutlined,
  ApiOutlined,
  GroupOutlined,
  BankOutlined
} from '@ant-design/icons';
import parser from 'html-react-parser';
import {
  Layout,
  Menu,
  Button,
  Avatar,
  notification,
  Row,
  Col,
  Tooltip,
  Space
} from 'antd';
import { Switch, Link } from 'react-router-dom';
import RouteWithSubRoutes from '../../components/RouteWithSubRoutes';
import './styles.module.css';
import styles from './styles.module.css';
import { withConfigContext } from '../../components/ConfigContext';
import axios from 'axios';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import Navigator from './components/Navigator';
import BranchDropdown from './components/BranchDropdown';
import ChangePassword from './components/ChangePassword';
import { handleApiError } from '../../services/utils/errorHandler';
import { isAuthorized } from '../../services/utils/authorizationHandler';
import { getUiConfig } from '../../services/utils/uiConfigHandler';
import { withTranslation } from 'react-i18next';
import LanguageChooser from '../../components/LocaleChooser';
import TooltipComponent from './components/TooltipComponent';
import { Footer } from 'antd/es/layout/layout';
import { randomizeImageUrlToAvoidLoadingFromCache } from '../../services/utils/urlUtils';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { archiveHubspotConversations } from '../../services/utils/hubspotChatHandler';
import logoIMG from '../../../public/images/submetering-white.png';
import shortLogoIMG from '../../../public/images/submetering-white-logo-only.png';
import SubMenu from 'antd/lib/menu/SubMenu';

const { Header, Content, Sider } = Layout;

export const RoutesContext = React.createContext();

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;

    this.state = {
      routes: props.routes,
      selectedKeys: [],
      deviceTypes: [],
      currentRoute: 'devices',
      isChangePasswordModalVisible: false,
      collapsed: false,
      tenantDomain: this.config.userDomain,
      run: true,
      stepIndex: 0
    };
  }

  componentDidMount() {
    if (this.config.enableNotificationCheck) {
      this.interval = setInterval(() => {
        this.fetchData();
      }, this.config.notificationCheckInterval);
    }
    this.setState({
      isTrackerEnabled: false,
      trackerServer: null
    });

    // if (isAuthorized(this.config.scopes, ['perm:view-configuration'])) {
    //   this.getGeneralConfigs();
    // }
    if (!localStorage.getItem('ui-tour')) {
      localStorage.setItem('ui-tour', 1);
      this.setState({ run: true });
    } else {
      this.setState({ run: false });
    }
  }

  getGeneralConfigs = () => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
          this.config.serverConfig.invoker.uri +
          this.config.serverConfig.invoker.deviceMgt +
          '/configuration'
      )
      .then(res => {
        if (res.status === 200) {
          let responseData = res.data.data.configuration;
          if (responseData) {
            for (let platformConfig of [...responseData]) {
              if (platformConfig.name === 'locationPublishing') {
                let locationConfig = JSON.parse(platformConfig.value);
                this.setState({
                  isTrackerEnabled: locationConfig.isTrackerEnabled,
                  trackerServer: locationConfig.traccarServer
                });
                break;
              }
            }
          }
          this.setState({ loading: true });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_generalConfigs') }),
          t
        );
      });
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  // fetch data from api
  fetchData = () => {
    const { t } = this.props;
    const apiUrl =
      window.location.origin +
      '/entgra-ui-request-handler/invoke' +
      // this.config.serverConfig.invoker.uri +
      '/device-mgt/v1.0' +
      '/notifications?status=NEW&offset=0&limit=1';

    // send request to the invoker
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const prevNotificationCookie = localStorage.getItem('notification');
          if (
            prevNotificationCookie &&
            prevNotificationCookie !== 'undefined'
          ) {
            const prevNotification = JSON.parse(prevNotificationCookie);
            if (
              prevNotification &&
              res.data.data.notifications.length > 0 &&
              prevNotification.id !== res.data.data.notifications[0].id &&
              res.data.data.notifications[0].description ===
                this.config.chatRequestDescription
            ) {
              notification.success({
                message: t('api_newNotificationMsg'),
                duration: 10,
                description: this.config.chatRequestDescription,
                btn: (
                  <Button onClick={this.onClickNotification}>
                    Go To Notifications
                  </Button>
                )
              });
            }
          } else if (
            res.data.data.notifications.length > 0 &&
            res.data.data.notifications[0].description ===
              this.config.chatRequestDescription
          ) {
            notification.success({
              message: t('api_newNotificationMsg'),
              duration: 10,
              description: this.config.chatRequestDescription,
              btn: (
                <Button onClick={this.onClickNotification}>
                  Go To Notifications
                </Button>
              )
            });
          }
          localStorage.setItem(
            'notification',
            JSON.stringify(res.data.data.notifications[0])
          );
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_notifications') }),
          t
        );
      });
  };

  onClickNotification = () => {
    window.location =
      window.location.origin +
      `/${this.config.serverConfig.platform}/notifications`;
  };

  setCurrentRoute = key => {
    this.setState({
      currentRoute: key
    });
  };

  logout = () => {
    const { t } = this.props;
    getUiConfig(this.config, t).then(uiConfig => {
      if (uiConfig !== undefined) {
        let logoutUri;
        if (uiConfig.isSsoEnable) {
          logoutUri =
            window.location.origin +
            this.props.context.serverConfig.ssoLogoutUri;
        } else {
          logoutUri =
            window.location.origin + this.props.context.serverConfig.logoutUri;
        }
        axios
          .post(logoutUri)
          .then(res => {
            // if the api call status is correct then user will logout and then it goes to login page
            if (res.status === 200) {
              if (uiConfig.isSsoEnable) {
                window.location =
                  window.location.origin +
                  this.config.serverConfig.ssoLoginUri +
                  '?redirect=' +
                  window.location.origin +
                  `/${this.config.appName}`;
              } else {
                window.location =
                  window.location.origin + `/${this.config.appName}/login`;
              }
            }
          })
          .catch(function(error) {
            notification.error({
              message: t('api_errorMsg'),
              duration: 0,
              description: t('api_logoutError')
            });
          });
      }
    });
  };

  trackerCon = () => {
    const { t } = this.props;
    getUiConfig(this.config, t).then(uiConfig => {
      let traccarUri = this.state.trackerServer + '/?token=';

      axios
        .get(
          window.location.origin +
            this.config.serverConfig.invoker.uri +
            '/traccar-mgt/v1.0/traccar/token'
        )
        .then(res => {
          if (res.status === 200) {
            this.setState({
              isModalVisible: false,
              loading: false
            });
            notification.success({
              message: t('api_successMsg'),
              duration: 4,
              description: t('api_loadConfigMsg', {
                label: t('label_traccar')
              })
            });
            console.log(res);
            window.open(traccarUri + res.data.data);
          }
        })
        .catch(error => {
          handleApiError(
            error,
            t('api_loadError', { label: t('label_traccar') }),
            t
          );
          this.setState({ loading: false });
        });
    });
  };

  onChangePassword = () => {
    this.setState({
      isChangePasswordModalVisible: true
    });
  };

  onCancelChangedPassword = () => {
    this.setState({
      isChangePasswordModalVisible: false
    });
  };

  getItem = (label, key, icon, children, className, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
      className
    };
  };

  sliderClick = item => {
    this.setState({ sliderSelectedTab: item.key });
    let path;

    if (item.key === 'store') {
      window.open('/store');
    } else if (item.key === 'app-publisher') {
      window.open(`/${this.config.publisherAppName}`);
    } else if (item.key === 'device-tracker') {
      this.trackerCon();
    } else if (item.key === 'allFactoryFlowLayoutsPenAssembly') {
      path = '/sub-metering-mgt/factory/flowlayouts';
    } else if (item.key === 'allFactoryFlowlayoutsWeeklySummary') {
      path = '/sub-metering-mgt/factory/weeklysummary';
    } else if (item.key === 'allOutputPenAssembly') {
      path = '/sub-metering-mgt/output/penassembly';
    } else if (item.key === 'allUsageView') {
      path = '/sub-metering-mgt/usage/view';
    } else if (item.key === 'allHistoricalUsage') {
      path = '/sub-metering-mgt/usage/historicalusage';
    } else if (item.key === 'allInvoiceView') {
      path = '/sub-metering-mgt/invoice/view';
    } else if (item.key === 'allFacilityMeterAssignment') {
      path = '/sub-metering-mgt/facility/assignment';
    } else if (item.key === 'allOtherDevicesHistory') {
      path = '/sub-metering-mgt/other/${deviceType}/${deviceId}';
    } else if (item.key === 'allMeterInventory') {
      path = '/sub-metering-mgt/meter/inventory';
    } else if (item.key === 'allMeterData') {
      path = '/sub-metering-mgt/meter/data';
    } else if (item.key === 'allMeterView') {
      path = '/sub-metering-mgt/meter/view';
    } else if (item.key === 'allOtherDevicesTemperature') {
      path = '/sub-metering-mgt/other/temperature';
    } else if (item.key === 'allOtherDevicesUPS') {
      path = '/sub-metering-mgt/other/ups';
    } else if (item.key === 'allMeterReadingsHistorical') {
      path = '/sub-metering-mgt/read/historical';
    } else if (item.key === 'allMeterReadingsCurrent') {
      path = '/sub-metering-mgt/read/current';
    } else if (item.key === 'allMeterReading') {
      path = '/sub-metering-mgt/read/operations/readings';
    } else if (item.key === 'allOperationResponse') {
      path = '/sub-metering-mgt/read/operations/activities';
    } else if (item.key === 'allInvoiceHistory') {
      path = '/sub-metering-mgt/invoice/history';
    } else if (item.key === 'allDeviceOrgView') {
      path = '/sub-metering-mgt/device-organization/view';
    } else if (item.key === 'allProfileHistoricalReading') {
      path = '/sub-metering-mgt/load/historical';
    } else if (item.key === 'allProfileData') {
      path = '/sub-metering-mgt/load/profile-data';
    } else if (item.key === 'allRawDataAnalytics') {
      path = '/sub-metering-mgt/reports/raw-data-analytics';
    } else if (item.key === 'allHourlyDataAnalytics') {
      path = '/sub-metering-mgt/reports/hourly-data-analytics';
    } else if (item.key === 'allDailyDataAnalytics') {
      path = '/sub-metering-mgt/reports/daily-data-analytics';
    } else if (item.key === 'allDailyEnergyConsumptionAnalytics') {
      path = '/sub-metering-mgt/reports/daily-energy-consumption-analytics';
    } else if (item.key === 'allDailyPowerQualityAnalytics') {
      path = '/sub-metering-mgt/reports/daily-power-quality-analytics';
    } else if (item.key === 'allMonthlyPowerConsumptionAnalytics') {
      path = '/sub-metering-mgt/reports/monthly-power-consumption-analytics';
    } else if (item.key === 'allWeeklyPowerConsumtionAnalytics') {
      path = '/sub-metering-mgt/reports/weekly-power-consumption-analytics';
    } else if (item.key === 'allBreakdowns') {
      path = '/sub-metering-mgt/breakdowns';
    } else if (item.key === 'allEnergy') {
      path = '/sub-metering-mgt/energy';
    } else if (item.key === 'allQuality') {
      path = '/sub-metering-mgt/quality';
    } else if (item.key === 'allOperatorsPenAssembly') {
      path = '/sub-metering-mgt/operators';
    } else if (item.key === 'allHourlyConsumption') {
      path = '/sub-metering-mgt/consumption';
    } else if (item.key === 'allProductsPenAssembly') {
      path = '/sub-metering-mgt/products';
    } else if (item.key === 'allDevicesPenAssembly') {
      path = '/sub-metering-mgt/devices';
      path = '/sub-metering-mgt/devices';
    } else if (item.key === 'deviceTypes') {
      path = '/sub-metering-mgt/devices/types';
      path = '/sub-metering-mgt/devices/types';
    } else if (item.key === 'certificates') {
      path = '/sub-metering-mgt/configurations/certificates';
      path = '/sub-metering-mgt/configurations/certificates';
    } else if (item.key === 'platform-configuration') {
      path =
        '/sub-metering-mgt/configurations/platform-configuration/general-config/general';
      ('/sub-metering-mgt/configurations/platform-configuration/general-config/general');
    } else if (
      item.key === 'policies' ||
      item.key === 'geo-fences' ||
      item.key === 'device-locations' ||
      item.key === 'users' ||
      item.key === 'roles' ||
      item.key === 'groups' ||
      item.key === 'dashboard' ||
      item.key === 'analytics'
    ) {
      path = '/sub-metering-mgt/' + item.key;
      path = '/sub-metering-mgt/' + item.key;
    } else {
      path = '/sub-metering-mgt/devices/' + item.key;
      path = '/sub-metering-mgt/devices/' + item.key;
    }

    this.props.history.push(path);
  };

  headerClick = item => {
    let path;

    if (item.key === 'store') {
      window.open('/store');
    } else if (item.key === 'user') {
      path = `/${this.config.appName}/users?add-new-user=true`;
    } else if (item.key === 'deviceType') {
      path = `/${this.config.appName}/devices/types?add-device-type=true`;
    } else if (item.key === 'role') {
      path = `/${this.config.appName}/roles?add-new-role=true`;
    } else if (item.key === 'group') {
      path = `/${this.config.appName}/groups?add-new-group=true`;
    } else if (item.key === 'policy') {
      path = `/${this.config.appName}/policy/add`;
    } else if (item.key === 'geo-fence') {
      path = `/${this.config.appName}/geo-fences?add-new-geo-fence=true`;
    } else if (item.key === 'certificate') {
      path = `/${this.config.appName}/configurations/certificates?add-new-certificate=true`;
    } else if (item.key === 'dep-profile') {
      path = `/${this.config.appName}/devices/dep-profiles?add-dep-profile=true`;
    } else if (item.key === 'notifications') {
      path = `/${this.config.appName}/notifications`;
    } else if (item.key === 'billing') {
      path = `/${this.config.appName}/billing`;
    } else if (item.key === 'change-password') {
      this.onChangePassword();
    } else if (item.key === 'ui-tour') {
      this.setState({ run: true, stepIndex: 0 });
    } else if (item.key === 'logout') {
      archiveHubspotConversations(this.config);
      this.logout();
    }

    this.props.history.push(path);
  };

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      [ACTIONS.CLOSE].includes(action)
    ) {
      this.setState({ run: false });
      this.setState({ stepIndex: 0 });
    }
  };

  render() {
    const { collapsed, run, stepIndex } = this.state;
    const { t, theme } = this.props;
    const logo = randomizeImageUrlToAvoidLoadingFromCache(theme.logo.logoUrl);
    const logoIcon = randomizeImageUrlToAvoidLoadingFromCache(
      theme.logoIcon.logoIconUrl
    );

    const steps = [
      {
        target: '#create-btn',
        title: t('label_manageConfigurationTitle'),
        content: t('label_createBtnTourGuideText'),
        continuous: true,
        disableBeacon: true
      },
      {
        target: '#profile',
        title: t('label_manageUserProfileTitle'),
        content: t('label_userProfileTourGuideText'),
        disableBeacon: true
      },
      {
        target: '#enrollDevices',
        title: t('label_enrollDeviceTitle'),
        content: (
          <div>
            <p>
              {t('label_enrollmentTourGuideText')}{' '}
              {t('label_referToTourGuideText')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/enrollment/`}
              >
                {t('label_enrollmentGuide')}
              </a>{' '}
              {t('label_moreInformationTourGuideText')}
            </p>
          </div>
        ),
        disableBeacon: true
      },
      {
        target: '#allDevicesPenAssembly',
        title: t('label_viewDevicesTitle'),
        content: (
          <div>
            <p>
              {t('label_allDevicesTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/device/`}
              >
                {t('label_enrolmentGuideDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true
      },
      {
        target: '#policies',
        title: t('label_policiesTitle'),
        content: (
          <div>
            <p>
              {t('label_policiesTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/policies/`}
              >
                {t('label_enrolmentGuidePolicyDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true
      },
      {
        target: '#configurations',
        title: t('label_manageConfigurationTitle'),
        content: (
          <div>
            <p>
              {t('label_configurationsTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/configurations/`}
              >
                {t('label_enrolmentGuideConfigurationDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true
      },
      {
        target: '#app-store',
        title: t('label_storeTitle'),
        content: (
          <div>
            <p>
              {t('label_appStoreTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/store/`}
              >
                {t('label_enrolmentGuideAppStoreDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true
      },
      {
        target: '#app-publisher',
        title: t('label_publisherTitle'),
        content: (
          <div>
            <p>
              {t('label_appPublisherTourGuideText')}{' '}
              {t('label_enrolmentGuideMoreInfo')}&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://docs.entgra.io/${this.config.docVersion}/publisher/`}
              >
                {t('label_enrolmentGuideAppPublisherDocLink')}
              </a>{' '}
            </p>
          </div>
        ),
        disableBeacon: true
      }
    ];

    // all items commented on this code may be used for later development in the submetering project
    const items = [
      this.getItem(
        t('home_menu_deviceOrgView'),
        'allDeviceOrgView',
        <DeploymentUnitOutlined />,
        null,
        styles.customItem
      ),
      this.getItem(
        t('home_menu_meterInventory'),
        'allMeterInventory',
        <BankOutlined />,
        null,
        styles.customItem
      ),
      this.getItem(<span id="otherDevices">{t('home_menu_other_devices')}</span>,
        'otherDevices',
        <AppstoreOutlined />,
        [
          this.getItem(
            t('home_menu_other_devices_temperature'),
            'allOtherDevicesTemperature',
            <DashboardOutlined />,
            null,
            styles.customItem
          ),
          this.getItem(
            t('home_menu_other_devices_ups'),
            'allOtherDevicesUPS',
            <CodeSandboxOutlined />,
            null,
            styles.customItem
          ),
        ]),
      this.getItem(
        <span id="analyticReports">{t('home_menu_analyticReports')}</span>,
        'analyticReports',
        <BarChartOutlined />,
        [
          this.getItem(
            t('home_menu_rawDataAnalytics'),
            'allRawDataAnalytics',
            <AreaChartOutlined />,
            null,
            styles.customItem
          ),
          this.getItem(
            t('home_menu_hourlyDataAnalytics'),
            'allHourlyDataAnalytics',
            <LineChartOutlined />,
            null,
            styles.customItem
          ),
          this.getItem(
            t('home_menu_dailyDataAnalytics'),
            'allDailyDataAnalytics',
            <FundFilled />,
            null,
            styles.customItem
          ),
          this.getItem(
            t('home_menu_dailyEnergyConsumptionAnalytics'),
            'allDailyEnergyConsumptionAnalytics',
            <PieChartOutlined />,
            null,
            styles.customItem
          ),
          this.getItem(
            t('home_menu_dailyPowerQualityAnalytics'),
            'allDailyPowerQualityAnalytics',
            <ThunderboltOutlined />,
            null,
            styles.customItem
          )
        ]
      )
    ];

    // all items commented on this code may be used for later development in the submetering project
    const headerItems = [
      // this.getItem(<LanguageChooser />, "language", null, null, "profile"),
      // this.config.isEnableMDMReportView &&
      //   this.getItem(
      //     <Tooltip title={t("home_menu_MDMReport")} placement="bottom">
      //       <Button
      //         href={"/mdm-reports"}
      //         target={"_blank"}
      //         shape="circle"
      //         icon={
      //           <Avatar
      //             style={{
      //               backgroundColor: "#388e9f",
      //               verticalAlign: "middle",
      //             }}
      //             size="small"
      //             gap={4}
      //           >
      //             {"R"}
      //           </Avatar>
      //         }
      //       />
      //     </Tooltip>,
      //     "report",
      //     null,
      //     null,
      //     null
      //   ),
      // isAuthorized(this.config.scopes, ["perm:notifications:view"]) &&
      //   this.getItem(
      //     <Tooltip title={t("home_menu_deviceNotification")} placement="bottom">
      //       <BellOutlined id="notify" className={styles.notificationButton} />
      //     </Tooltip>,
      //     "notifications",
      //     null,
      //     null,
      //     null
      //   ),
      this.getItem(
        <Avatar
          id="profile"
          size={32}
          className={styles.avatar}
          icon={<UserOutlined />}
        />,
        'user-avatar',
        null,
        [
          this.getItem(this.config.user, this.config.user),
          // this.getItem(t("home_menu_changePassword"), "change-password"),
          // !this.config.excludedBillingTenants?.includes(
          //   this.state.tenantDomain
          // ) && this.getItem(t("label_Usage"), "billing"),
          // this.getItem(t("home_menu_uiTour"), "ui-tour"),
          this.getItem(t('home_menu_logout'), 'logout')
        ],
        null
      )
    ];

    return (
      <>
        <Joyride
          steps={steps}
          showSkipButton={true}
          continuous={true}
          tooltipComponent={TooltipComponent}
          autoStart
          callback={this.handleJoyrideCallback}
          run={run}
          stepIndex={stepIndex}
        />
        <Layout>
          <Sider
            className={'site-layout-background'}
            breakpoint="lg"
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            width={'250px'}
            style={{ minHeight: '100vh' }}
          >
            <div>
              <Link to={`/${this.config.appName}`}>
                <div className={collapsed ? styles.logoCollapsed : styles.logo}>
                  {collapsed ? (
                    <img src={shortLogoIMG} alt="Entgra" />
                  ) : (
                    <img src={logoIMG} alt="Submetering By Entgra" />
                  )}
                </div>
              </Link>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              onClick={this.sliderClick}
              selectedKeys={[this.state.currentRoute]}
              defaultOpenKeys={['sub-metering-mgt', 'devices']}
              style={{
                background: 'transparent'
              }}
              items={items}
            />
          </Sider>
          <Layout
          className="site-layout"
          >
            <Header className={styles.header}>
              <Row>
                <Col flex={2}>
                  <AimOutlined className={styles.searchIcon} />
                  <Navigator />
                </Col>
                <Col flex={3}>
                  <Row justify="end">
                  <BranchDropdown />
                  <Menu
                    mode="horizontal"
                    className={styles.rightAlignedMenu}
                    onClick={this.headerClick}
                    selectedKeys={[this.state.currentRoute]}
                    items={headerItems}
                  />
                  </Row>
                </Col>
              </Row>
            </Header>

            <RoutesContext.Provider
              value={{ setCurrentRoute: this.setCurrentRoute }}
            >
              <Content style={{ margin: '10px 10px' }}>
                <Layout
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    minHeight: '80vh'
                  }}
                >
                  <Switch>
                    {this.state.routes.map(route => (
                      <RouteWithSubRoutes key={route.path} {...route} />
                    ))}
                  </Switch>
                </Layout>
              </Content>
            </RoutesContext.Provider>
            <Footer
              style={{
                textAlign: 'center',
                position: 'sticky',
                width: '100%',
                bottom: 0,
                height: 20,
                paddingBottom: 40
              }}
            >
              {parser(
                'Entgra Sub Metering | © 2024 , All Rights Reserved.'
              )}
              {/* {ReactHtmlParser(theme.footerText)} */}
            </Footer>
          </Layout>
          <ChangePassword
            isChangePasswordModalVisible={
              this.state.isChangePasswordModalVisible
            }
            onCancelChangedPassword={this.onCancelChangedPassword}
            logout={this.logout}
          />
        </Layout>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(Home));

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { TileLayer, Circle, FeatureGroup, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { Select, Button, Form, Input, Typography, Divider, Alert } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { isAuthorized } from '../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

const mapLayout = {
  wrapperCol: { span: 24 },
};

class GeoFenceForm extends React.Component {
  formRef = React.createRef();
  editableFG = React.createRef();
  customMap = React.createRef();
  geofenceLayer;
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      addModalVisible: false,
      geoFenceData: null,
      isFenceCreated: false,
      error: null,
      editableFG: null,
      generalPlatformConfigData: [],
      values: [],
    };
  }

  componentDidMount() {
    if (isAuthorized(this.config.scopes, ['perm:view-configuration'])) {
      this.getGeneralConfigs();
    }
    if (this.props.values) {
      let geoFenceFieldData = this.setFormFieldData();
      this.setState({
        geoFenceData: geoFenceFieldData.geoFenceData,
        payloadValues: geoFenceFieldData.payloadValues,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.modalVisibility &&
      this.props.values &&
      this.state.geoFenceData === null
    ) {
      let geoFenceFieldData = this.setFormFieldData();

      this.setState({
        geoFenceData: geoFenceFieldData.geoFenceData,
        payloadValues: geoFenceFieldData.payloadValues,
      });
    }
  }

  setFormFieldData = () => {
    let groupIds = [];
    let values = { ...this.props.values };
    values.eventConfig.map(event => {
      let key = `eventLogic-${event.eventLogic.toLowerCase()}`;
      values[key] = event.actions.map(action => {
        return JSON.stringify(action);
      });
    });
    for (var key in this.props.values.groupNames) {
      if (this.props.values.groupNames.hasOwnProperty(key)) {
        groupIds.push(key);
      }
    }
    values.groupIds = groupIds;
    this.formRef.current.setFieldsValue(values);
    let geoFenceData;
    if (this.props.values.fenceShape === 'polygon') {
      geoFenceData = {
        geoJson: this.props.values.geoJson,
        fenceShape: this.props.values.fenceShape,
      };
    } else {
      geoFenceData = {
        latitude: this.props.values.latitude,
        longitude: this.props.values.longitude,
        radius: this.props.values.radius,
        fenceShape: this.props.values.fenceShape,
      };
    }
    let formValues = {
      geoFenceData: geoFenceData,
      payloadValues: values,
    };
    return formValues;
  };

  onEditPath = e => {
    let numEdited = 0;
    e.layers.eachLayer(layer => {
      numEdited += 1;
    });
    if (numEdited > 0) {
      this.geofenceLayer = e.layers.getLayers()[0];
    }
  };

  onCreate = e => {
    this.geofenceLayer = e.layer;
    this.onCancelMultipleCreations();
    this.setState({
      isFenceCreated: true,
      error: null,
    });
  };

  onDeleted = e => {
    let numDeleted = 0;
    e.layers.eachLayer(layer => {
      numDeleted += 1;
    });
    if (numDeleted > 0) {
      this.setState({
        geoFenceData: null,
        isFenceCreated: false,
        error: null,
      });
    }
  };

  onFinish = values => {
    const { t } = this.props;
    let eventConfig = [];
    for (const key in values) {
      if (key.includes('eventLogic-')) {
        let event = {};
        if (values[key]) {
          event.eventLogic = key.replace('eventLogic-', '').toUpperCase();
          event.actions = values[key].map(action => {
            return JSON.parse(action);
          });
          if (this.props.values) {
            let actionIndex = this.props.values.eventConfig.findIndex(
              x => x.eventLogic === event.eventLogic,
            );
            if (actionIndex >= 0) {
              event.id = this.props.values.eventConfig[actionIndex].id;
            }
          }
          eventConfig.push(event);
        }
        delete values[key];
      }
    }
    values.eventConfig = eventConfig;

    values = { ...values, ...this.state.geoFenceData };
    const drawnItems = this.editableFG.current.leafletElement.getLayers();
    if (
      this.state.geoFenceData &&
      !this.state.error &&
      eventConfig.length > 0
    ) {
      values = { ...values, ...this.state.geoFenceData };
      this.props.callback(values);
      this.formRef.current.resetFields();
      this.editableFG.current.leafletElement.removeLayer(drawnItems[0]);
      this.setState({
        geoFenceData: null,
      });
    } else if (!this.state.geoFenceData && !this.state.error) {
      this.setState({
        error: t('form_geoFenceRequiredMsg'),
      });
    } else if (eventConfig.length === 0) {
      this.setState({
        error: t('form_eventConfigurationRequiredMsg'),
      });
    }
  };

  onDrawStop = e => {
    const geofenceType = e.layerType;
    let geoFenceData;
    if (geofenceType === 'circle') {
      geoFenceData = {
        latitude: this.geofenceLayer.getLatLng().lat,
        longitude: this.geofenceLayer.getLatLng().lng,
        radius: parseFloat(this.geofenceLayer.options.radius.toFixed(1)),
        fenceShape: geofenceType,
      };
    } else if (geofenceType === 'polygon') {
      geoFenceData = {
        geoJson: JSON.stringify(this.geofenceLayer.getLatLngs()),
        fenceShape: geofenceType,
      };
    }
    this.setState({
      geoFenceData,
    });
  };

  // remove old polygon when creating new one
  onCancelMultipleCreations = () => {
    const drawnItems = this.editableFG.current.leafletElement.getLayers();
    if (Object.keys(drawnItems).length > 1) {
      const layer = drawnItems[0];
      this.editableFG.current.leafletElement.removeLayer(layer);
    }
  };

  onEditStop = e => {
    let geoFenceData;
    if (!this.geofenceLayer.editing.latlngs) {
      geoFenceData = {
        latitude: this.geofenceLayer.getLatLng().lat,
        longitude: this.geofenceLayer.getLatLng().lng,
        radius: parseFloat(this.geofenceLayer.options.radius.toFixed(1)),
        fenceShape: 'circle',
      };
    } else if (this.geofenceLayer.editing.latlngs) {
      geoFenceData = {
        geoJson: JSON.stringify(this.geofenceLayer.getLatLngs()),
        fenceShape: 'polygon',
      };
    }
    this.setState({
      geoFenceData,
      error: null,
    });
  };

  onDeleteStart = e => {
    const { t } = this.props;
    this.setState({
      error: t('geoFenceDelete_infoTxt'),
    });
  };

  onDeleteStop = e => {
    this.setState({
      error: null,
    });
  };

  onValuesChange = () => {
    if (this.state.error !== null) {
      this.setState({
        error: null,
      });
    }
  };

  getGeneralConfigs = () => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/configuration',
      )
      .then(res => {
        if (res.status === 200) {
          let responseData = res.data.data.configuration;
          if (responseData) {
            this.setState({ generalPlatformConfigData: responseData });
          }
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_generalConfigs') }),
          t,
        );
      });
  };

  render() {
    const { action, values, groups, t } = this.props;
    const { geoFenceData, error, generalPlatformConfigData } = this.state;
    let fenceInActions = [];
    let fenceOutActions = [];
    let stationaryActions = [];
    let center = [6.93597, 79.849525];

    if (values) {
      if (values.fenceShape === 'polygon') {
        const geoCoordinates = JSON.parse(values.geoJson);
        center = [geoCoordinates[0][0].lat, geoCoordinates[0][0].lng];
      } else {
        center = [values.latitude, values.longitude];
      }
    }

    if (generalPlatformConfigData) {
      generalPlatformConfigData.forEach(config => {
        if (config.name === 'GEO_FENCE_IN') {
          fenceInActions = JSON.parse(config.value).actionTypes;
        } else if (config.name === 'GEO_FENCE_OUT') {
          fenceOutActions = JSON.parse(config.value).actionTypes;
        } else if (config.name === 'GEO_FENCE_STATIONARY') {
          stationaryActions = JSON.parse(config.value).actionTypes;
        }
      });
    }

    const attribution = this.config.geoMap.attribution;
    const url = this.config.geoMap.url;
    return (
      <div>
        {(!isAuthorized(this.config.scopes, [
          ['perm:admin-groups:view', 'perm:groups:groups'],
        ]) ||
          !isAuthorized(this.config.scopes, ['perm:view-configuration'])) && (
            <div>
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/admin/certificates/view,\n' +
                  '/permission/admin/device-mgt/admin/groups/view OR\n' +
                  '/permission/admin/device-mgt/groups/view'
                }
              />
              <Alert
                message={t('noPerm_someFields')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}
        <Form
          {...formItemLayout}
          name="AddNewGeoFenceForm"
          onValuesChange={this.onValuesChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <Form.Item
            label={t('label_geoFenceName')}
            name="fenceName"
            rules={[
              {
                required: true,
                message: t('form_geoFenceNameRequiredMsg'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t('label_description')} name="description">
            <Input />
          </Form.Item>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/admin/groups/view OR\n' +
              '/permission/admin/device-mgt/groups/view'
            }
          />
          <Form.Item
            label={t('label_deviceGroup')}
            name="groupIds"
            rules={[
              {
                required: true,
                message: t('form_deviceGroupsRequiredMsg'),
              },
            ]}
          >
            <Select
              mode={'multiple'}
              disabled={
                !isAuthorized(this.config.scopes, [
                  ['perm:admin-groups:view', 'perm:groups:groups'],
                ])
              }
            >
              {groups.map((group, index) => (
                <Option key={index} value={JSON.stringify(group.id)}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider plain>{t('eventConfiguration_descriptionTxt')}</Divider>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/platform-configurations/view'
            }
          />
          <Form.Item label={t('label_in')} name="eventLogic-in">
            <Select
              mode={'multiple'}
              disabled={
                !isAuthorized(this.config.scopes, ['perm:view-configuration'])
              }
            >
              {fenceInActions.map(action => (
                <Option
                  value={JSON.stringify({ actionType: action })}
                  key={action}
                >
                  {action}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/platform-configurations/view'
            }
          />
          <Form.Item label={t('label_out')} name="eventLogic-out">
            <Select
              mode={'multiple'}
              disabled={
                !isAuthorized(this.config.scopes, ['perm:view-configuration'])
              }
            >
              {fenceOutActions.map(action => (
                <Option
                  value={JSON.stringify({ actionType: action })}
                  key={action}
                >
                  {action}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <HtmlComments
            permission={
              '/permission/admin/device-mgt/platform-configurations/view'
            }
          />
          <Form.Item label={t('label_stationary')} name="eventLogic-stationary">
            <Select
              mode={'multiple'}
              disabled={
                !isAuthorized(this.config.scopes, ['perm:view-configuration'])
              }
            >
              {stationaryActions.map(action => (
                <Option
                  value={JSON.stringify({ actionType: action })}
                  key={action}
                >
                  {action}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...mapLayout}
            extra={error ? <Text type="danger">{error}</Text> : null}
          >
            {/* <Map */}
            {/*   ref={this.customMap} */}
            {/*   center={center} */}
            {/*   zoom={15} */}
            {/*   style={{ height: 500, width: 'auto' }} */}
            {/* > */}
            {/*   <TileLayer url={url} attribution={attribution} /> */}
            {/*   <FeatureGroup ref={this.editableFG}> */}
            {/*     <EditControl */}
            {/*       position="topright" */}
            {/*       onEdited={this.onEditPath} */}
            {/*       onCreated={this.onCreate} */}
            {/*       onDeleted={this.onDeleted} */}
            {/*       onEditStart={this.onEditStart} */}
            {/*       onDeleteStart={this.onDeleteStart} */}
            {/*       onEditStop={this.onEditStop} */}
            {/*       onDrawStop={this.onDrawStop} */}
            {/*       onDeleteStop={this.onDeleteStop} */}
            {/*       draw={{ */}
            {/*         polyline: false, */}
            {/*         marker: false, */}
            {/*         circlemarker: false, */}
            {/*         rectangle: false, */}
            {/*       }} */}
            {/*     /> */}
            {/*     {values && */}
            {/*       geoFenceData && */}
            {/*       (geoFenceData.fenceShape === 'circle' || */}
            {/*         !geoFenceData.fenceShape) && ( */}
            {/*         <Circle */}
            {/*           center={[geoFenceData.latitude, geoFenceData.longitude]} */}
            {/*           radius={geoFenceData.radius} */}
            {/*         /> */}
            {/*       )} */}
            {/*     {values && */}
            {/*       geoFenceData && */}
            {/*       geoFenceData.fenceShape === 'polygon' && ( */}
            {/*         <Polygon positions={JSON.parse(geoFenceData.geoJson)} /> */}
            {/*       )} */}
            {/*   </FeatureGroup> */}
            {/* </Map> */}
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                !isAuthorized(this.config.scopes, [
                  ['perm:admin-groups:view', 'perm:groups:groups'],
                  'perm:view-configuration',
                ])
              }
            >
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/platform-configurations/view\n' +
                  '/permission/admin/device-mgt/admin/groups/view OR\n' +
                  '/permission/admin/device-mgt/groups/view'
                }
              />
              {t(`label_${action.toLowerCase()}`)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GeoFenceForm));

/*
 * Copyright (C) 2021. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 *  Licensed under the Entgra Commercial License,
 *  Version 1.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *
 *  Unless required by applicable law or agreed to in writing,
 *  software distributed under the License is distributed on an
 *  "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 *  KIND, either express or implied.  See the License for the
 *  specific language governing permissions and limitations
 *  under the License.
 *
 *  You may obtain a copy of the License at
 *  https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Row,
  Col,
  Typography,
  DatePicker,
  Collapse,
  notification,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import styles from '../../../../styles.module.css';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import IframeWrapper from '../../../../../../components/IframeWrapper';
import { ReconciliationOutlined, SearchOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { RoutesContext } from '../../../../../../index';
import moment from 'moment';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import Filter from '../../../../../../components/Filter';
import BreadcrumbComponent from '../../../../../../../../components/BreadCrumb';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
let start = '';
let end = '';

const routes = (appName, t) => {
  return [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home'),
    },
    {
      path: `/${appName}/analytics`,
      breadcrumbName: t('home_menu_analytics'),
    },
    {
      path: `/${appName}/analytics/device-connectivity`,
      breadcrumbName: t('label_deviceConnectivity'),
    },
    {
      breadcrumbName: t('label_mobileConnectivity'),
    },
  ];
};

let setSearchFields = t => {
  return [
    {
      name: 'name',
      type: 'input',
      placeholder: t('label_deviceName'),
      label: t('label_name'),
    },
    {
      name: 'status',
      placeholder: t('label_status'),
      type: 'select',
      values: ['All'],
      label: t('label_status'),
    },
    {
      name: 'type',
      placeholder: t('label_deviceType'),
      type: 'select',
      values: ['All'],
      label: t('label_type'),
    },
    {
      name: 'operator',
      placeholder: t('label_operator'),
      type: 'select',
      values: ['All'],
      label: t('label_operator'),
    },
    {
      name: 'version',
      placeholder: t('label_agentVersion'),
      type: 'select',
      values: ['All'],
      label: t('label_agentVersion'),
    },
    {
      name: 'threshold',
      placeholder: t('label_signalThreshold'),
      type: 'select',
      values: ['-110'],
      label: t('label_signalThreshold'),
    },
  ];
};

class MobileConnectivity extends React.Component {
  constructor(props) {
    super(props);
    this.config = props.context;
    end = moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
        new Date().getMilliseconds(),
      ),
    );
    end = end.add(1, 'hours');
    start = moment().startOf('day');
    start = start.add(-1, 'weeks');
    this.state = {
      start: start,
      end: end,
      threshold: [-100, -90, -80, -70, -60, -50, -40, -30],
      selectedThreshold: -110,
      filterFields: [],
      queryParams: new URLSearchParams(this.props.location.search),
      selectedOperator: new URLSearchParams(this.props.location.search).get(
        'operator',
      ),
      selectedDeviceType: 'All',
      selectedStatus: 'All',
      selectedDeviceName: 'All',
      deviceNameURLParam: 'All',
      selectedAgentVersion: 'All',
      overtimePanelId: 2,
      showSearchBar: true,
      showRangePicker: true,
      selectedLanguage: this.props.i18n.language,
    };
  }

  componentDidMount() {
    this.context.setCurrentRoute('mobileConnectivity');
    this.getDeviceFilters();
    this.showControllers();
  }

  fetchFilterData = (params, values) => {
    this.onChangeFilters(values);
  };

  onChangeFilters = filters => {
    const { selectedDeviceName } = this.state;
    let type;
    let status;
    let operator;
    let version;
    let threshold;
    let name = selectedDeviceName;

    if (filters.hasOwnProperty('type')) {
      type = filters.type;
    } else {
      type = 'All';
    }

    if (filters.hasOwnProperty('status')) {
      status = filters.status;
    } else {
      status = 'All';
    }

    if (filters.hasOwnProperty('operator')) {
      operator = filters.operator;
    } else {
      operator = 'All';
    }

    if (filters.hasOwnProperty('version')) {
      version = filters.version;
    } else {
      version = 'All';
    }

    if (filters.hasOwnProperty('threshold')) {
      threshold = filters.threshold;
    } else {
      threshold = '-110';
    }

    if (filters.hasOwnProperty('name') && filters.name !== selectedDeviceName) {
      name = filters.name;
      this.fetchDeviceByName(name);
    } else if (!filters.hasOwnProperty('name')) {
      name = 'All';
      this.setState({ deviceNameURLParam: name });
    }

    this.setState({
      selectedDeviceType: type,
      selectedStatus: status,
      selectedOperator: operator,
      selectedAgentVersion: version,
      selectedThreshold: threshold,
      selectedDeviceName: name,
    });
  };

  fetchDeviceByName = deviceName => {
    const { t } = this.props;
    axios
      .get(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/devices?offset=0&limit=10&name=' +
        deviceName,
      )
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data;
          if (data.count > 0) {
            let deviceParam = '';
            if (data.count === 1) {
              deviceParam += data.devices[0].name;
            } else {
              data.devices.map((device, index, { length }) => {
                deviceParam += device.name;
                if (index + 1 !== length) {
                  deviceParam += '&var-deviceName=';
                }
              });
            }
            this.setState({ deviceNameURLParam: deviceParam });
          } else {
            notification.error({
              message: t('api_errorMsg'),
              duration: 5,
              description: t('deviceNameNotFound_errorTxt'),
            });
          }
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deviceFilterRetrieveError'), t);
      });
  };

  onChangeDateRange = (dates, dateString) => {
    this.setState({
      start: dateString[0],
      end: dateString[1],
      isButtonEnable: true,
    });
    this.renderOvertimeCharts(dateString);
  };

  renderOvertimeCharts = dateString => {
    let chartId = 2;
    let difference = moment.duration(
      moment(dateString[1]).diff(moment(dateString[0])),
    );
    let differenceInDays = parseInt(difference.asDays(), 10);

    if (differenceInDays < 2) {
      chartId = 6;
    } else if (differenceInDays < 56) {
      chartId = 2;
    } else if (differenceInDays >= 56) {
      chartId = 3;
    }
    this.setState({ overtimePanelId: chartId });
  };

  getDeviceFilters = () => {
    const { t } = this.props;
    const { threshold } = this.state;
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt;
    let deviceFiltersAPIUrl = apiUrl + '/devices/filters';
    let deviceOperatorsAPIUrl = apiUrl + '/reports/filters';
    const requestFilters = axios.get(deviceFiltersAPIUrl);
    const requestOperators = axios.get(deviceOperatorsAPIUrl);

    axios
      .all([requestFilters, requestOperators])
      .then(
        axios.spread((...responses) => {
          const responseFilters = responses[0];
          const responseOperators = responses[1];
          if (
            responseFilters.status === 200 &&
            responseOperators.status === 200
          ) {
            const deviceFilters = responseFilters.data.data;
            const reportFilters = responseOperators.data.data;
            let searchFields = setSearchFields(t);
            searchFields.map(searchField => {
              if (searchField.name === 'status') {
                searchField.values.push(...deviceFilters.statuses);
              }
              if (searchField.name === 'type') {
                searchField.values.push(...deviceFilters.deviceTypes);
              }
              if (searchField.name === 'operator') {
                searchField.values.push(...reportFilters.deviceOperators);
              }
              if (searchField.name === 'version') {
                searchField.values.push(...reportFilters.agentVersions);
              }
              if (searchField.name === 'threshold') {
                searchField.values.push(...threshold);
              }
            });
            this.setState({ filterFields: searchFields });
          }
        }),
      )
      .catch(error => {
        handleApiError(error, t('api_deviceFilterRetrieveError'), t);
      });
  };

  showControllers = () => {
    let panelId = this.state.queryParams.get('panelId');
    let showSearchBar = true;
    let showRangePicker = true;

    if (panelId === '2' || panelId === '3' || panelId === '5') {
      showRangePicker = false;
    }
    if (panelId === '6') {
      showSearchBar = false;
    }
    this.setState({
      showSearchBar: showSearchBar,
      showRangePicker: showRangePicker,
    });
  };

  render() {
    const { t } = this.props;
    const {
      selectedThreshold,
      selectedOperator,
      queryParams,
      filterFields,
      selectedDeviceType,
      selectedStatus,
      selectedAgentVersion,
      deviceNameURLParam,
      overtimePanelId,
      showSearchBar,
      showRangePicker,
      selectedLanguage,
    } = this.state;
    const panelId = queryParams.get('panelId');
    const grafanaEmbedLinks = this.config.grafanaConfig.deviceConnectivity;
    const fromInMills = moment(this.state.start).unix() * 1000;
    const ToInMills = moment(this.state.end).unix() * 1000;

    return (
      <div>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={
              <Title level={1}>
                <span className={styles.icon}>
                  <ReconciliationOutlined />
                </span>
                {t('label_mobileConnectivity')}
              </Title>
            }
            ghost={false}
            breadcrumb={
              <BreadcrumbComponent
                breadcrumbList={routes(this.config.appName, t)}
              />
            }
          />
          <div className={styles.container}>
            <div className={styles.controllerDiv}>
              {showSearchBar && (
                <Collapse
                  bordered={false}
                  expandIcon={() => <SearchOutlined />}
                  className={styles.searchCollapse}
                >
                  <Panel
                    header={t('form_searchDevices')}
                    key="1"
                    style={{ borderColor: '#fff' }}
                  >
                    <Row>
                      <Col span={23}>
                        <Filter
                          filters={filterFields}
                          callback={(params, values) =>
                            this.fetchFilterData({}, values)
                          }
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              )}
              {showRangePicker && (
                <Row>
                  <Col className={styles.col}>
                    <RangePicker
                      defaultValue={[this.state.start, this.state.end]}
                      showTime
                      onChange={this.onChangeDateRange}
                    />
                  </Col>
                </Row>
              )}
            </div>
            {panelId === null && (
              <Row>
                <Col span={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.connectivityTypeList +
                        '&panelId=2' +
                        '&var-connectionType=MOBILE' +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-deviceType=' +
                        selectedDeviceType +
                        '&var-status=' +
                        selectedStatus +
                        '&var-agentVersion=' +
                        selectedAgentVersion +
                        '&var-deviceName=' +
                        deviceNameURLParam +
                        '&var-label_panelName2=' +
                        selectedLanguage +
                        '&var-label_deviceName=' +
                        selectedLanguage +
                        '&var-label_deviceType=' +
                        selectedLanguage +
                        '&var-label_agentVersion=' +
                        selectedLanguage +
                        '&var-label_operator=' +
                        selectedLanguage +
                        '&var-label_signalStrength=' +
                        selectedLanguage +
                        '&var-label_status=' +
                        selectedLanguage
                      }
                    />
                  </div>
                </Col>
                <Col xl={12} xs={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.connectivityTypeMap +
                        '&panelId=2' +
                        '&var-connectionType=MOBILE' +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-label_panelName2=' +
                        selectedLanguage +
                        '&var-label_deviceIdentification=' +
                        selectedLanguage +
                        '&var-label_deviceModel=' +
                        selectedLanguage +
                        '&var-label_city=' +
                        selectedLanguage +
                        '&var-label_state=' +
                        selectedLanguage +
                        '&var-label_country=' +
                        selectedLanguage +
                        '&var-label_operator=' +
                        selectedLanguage +
                        '&var-label_deviceType=' +
                        selectedLanguage +
                        '&var-label_status=' +
                        selectedLanguage
                      }
                    />
                  </div>
                </Col>
                <Col xl={12} xs={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.connectivityTypeMap +
                        '&panelId=3' +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-threshold=' +
                        selectedThreshold +
                        '&var-label_panelName3=' +
                        selectedLanguage +
                        '&var-label_deviceIdentification=' +
                        selectedLanguage +
                        '&var-label_operator=' +
                        selectedLanguage +
                        '&var-label_deviceType=' +
                        selectedLanguage +
                        '&var-label_status=' +
                        selectedLanguage
                      }
                    />
                  </div>
                </Col>
                <Col xl={12} xs={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.connectivityTypeMap +
                        '&panelId=5' +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-label_panelName5=' +
                        selectedLanguage +
                        '&var-label_deviceIdentification=' +
                        selectedLanguage +
                        '&var-label_operator=' +
                        selectedLanguage +
                        '&var-label_deviceType=' +
                        selectedLanguage +
                        '&var-label_status=' +
                        selectedLanguage
                      }
                    />
                  </div>
                </Col>
                <Col xl={12} xs={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.historicallyWeakSignalByProvider +
                        '&panelId=' +
                        overtimePanelId +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-label_panelName' +
                        overtimePanelId +
                        '=' +
                        selectedLanguage
                      }
                      duration={`&from=${fromInMills}&to=${ToInMills}`}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {panelId === '2' && (
              <Row>
                <Col span={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.connectivityTypeList +
                        '&panelId=2' +
                        '&var-connectionType=MOBILE' +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-deviceType=' +
                        selectedDeviceType +
                        '&var-status=' +
                        selectedStatus +
                        '&var-agentVersion=' +
                        selectedAgentVersion +
                        '&var-deviceName=' +
                        deviceNameURLParam +
                        '&var-label_panelName2=' +
                        selectedLanguage +
                        '&var-label_deviceName=' +
                        selectedLanguage +
                        '&var-label_deviceType=' +
                        selectedLanguage +
                        '&var-label_agentVersion=' +
                        selectedLanguage +
                        '&var-label_operator=' +
                        selectedLanguage +
                        '&var-label_signalStrength=' +
                        selectedLanguage +
                        '&var-label_status=' +
                        selectedLanguage
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}
            {panelId === '3' && (
              <Row>
                <Col span={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.connectivityTypeMap +
                        '&panelId=3' +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-threshold=' +
                        selectedThreshold +
                        '&var-label_panelName3=' +
                        selectedLanguage +
                        '&var-label_deviceIdentification=' +
                        selectedLanguage +
                        '&var-label_operator=' +
                        selectedLanguage +
                        '&var-label_deviceType=' +
                        selectedLanguage +
                        '&var-label_status=' +
                        selectedLanguage
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}
            {panelId === '5' && (
              <Row>
                <Col span={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.connectivityTypeMap +
                        '&panelId=5' +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-label_panelName5=' +
                        selectedLanguage +
                        '&var-label_deviceIdentification=' +
                        selectedLanguage +
                        '&var-label_operator=' +
                        selectedLanguage +
                        '&var-label_deviceType=' +
                        selectedLanguage +
                        '&var-label_status=' +
                        selectedLanguage
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}
            {panelId === '6' && (
              <Row>
                <Col span={24}>
                  <div className={styles.iframeContainer}>
                    <IframeWrapper
                      iframeSrc={
                        grafanaEmbedLinks.historicallyWeakSignalByProvider +
                        '&panelId=' +
                        overtimePanelId +
                        '&var-operator=' +
                        selectedOperator +
                        '&var-label_panelName' +
                        overtimePanelId +
                        '=' +
                        selectedLanguage
                      }
                      duration={`&from=${fromInMills}&to=${ToInMills}`}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MobileConnectivity.contextType = RoutesContext;
export default withConfigContext(withTranslation()(MobileConnectivity));

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext } from 'react';
import Header from './components/Header';
import { Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import ConfigContext from '../../../../../../components/ConfigContext';
import PolicyInfo from './components/PolicyInfo';
import CorrectiveInfo from './components/CorrectiveInfo';
import { combinePolicyDevicePayloadWithUIPayload } from '../../services/payloadHandler';
import { Result, Spin } from 'antd';
import GroupInfo from './components/GroupsInfo';
import styles from '../../../SingleDevice/styles.module.css';
import Authorized from '../../../../../../components/Authorized';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { useTranslation } from 'react-i18next';

export const PolicyDetailsContext = React.createContext();
const PolicyDetailsProvider = PolicyDetailsContext.Provider;

const PolicyView = props => {
  const { policyId } = props.match.params;
  const { config } = useContext(ConfigContext);
  let policyFeatureList = [];
  const { t } = useTranslation();
  const [currentRoute, setCurrentRoute] = useState('policy-information');
  const [policy, setPolicy] = useState(null);
  const [policyUIConfigurations, setPolicyUIConfigurations] = useState(null);

  const routes = [
    {
      path: `/${config.appName}/policy/view/:policyId/policy-information`,
      component: PolicyInfo,
      exact: true,
    },
    {
      path: `/${config.appName}/policy/view/:policyId/corrective-information`,
      component: CorrectiveInfo,
      exact: true,
    },
    {
      path: `/${config.appName}/policy/view/:policyId/group-information`,
      component: GroupInfo,
      exact: true,
    },
  ];

  const policyDetails = {
    policyId,
    policy,
    policyUIConfigurations,
    currentRoute,
    setCurrentRoute,
  };

  useEffect(() => {
    fetchPolicy();
  }, []);

  const modifyUIPayload = policyUIPayload => {
    setPolicyUIConfigurations(
      combinePolicyDevicePayloadWithUIPayload(
        policyUIPayload,
        policyFeatureList,
      ),
    );
  };

  const fetchPolicy = () => {
    let apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      config.serverConfig.invoker.deviceMgt +
      `/policies/${policyId}`;

    // send request to the invokers
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          policyFeatureList = res.data.data.profile.profileFeaturesList;
          setPolicy(res.data.data);
          if (
            isAuthorized(config.scopes, [
              [
                'perm:android:view-configuration',
                'perm:ios:view-configuration',
                'perm:windows:view-configuration',
              ],
            ])
          ) {
            getPolicyConfigJson(res.data.data.profile.deviceType);
          }
        } else {
          throw new Error();
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_selectedPolicy') }),
          t,
        );
      });
  };

  const getPolicyConfigJson = type => {
    let apiUrl =
      window.location.origin +
      config.serverConfig.invoker.uri +
      '/device-mgt/' +
      type +
      '/v1.0/configuration/policy-ui';
    // send request to the invokers
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const policyUIPayload = res.data.data.policies.map(policy => {
            policy.isActivePolicy = false;
            return policy;
          });
          modifyUIPayload(policyUIPayload);
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('label_policyDetails') }),
          t,
        );
      });
  };

  // Display a loading animation if the policy is null
  if (policy == null && policyUIConfigurations == null) {
    return (
      <Spin className={styles.spinner} size="large" tip={t('label_loading')} />
    );
  }

  return (
    <div>
      <HtmlComments
        permission={
          '/permission/admin/device-mgt/policies/view,\n' +
          '/permission/admin/device-mgt/platform-configurations/view OR\n' +
          '/permission/admin/device-mgt/devices/enroll/android'
        }
      />
      <Authorized
        scope={['perm:policies:get-policy-details']}
        yes={
          <div>
            <PolicyDetailsProvider value={policyDetails}>
              <Header />
              <div className={styles.content}>
                <Switch>
                  <Redirect
                    exact
                    from={`/${config.appName}/policy/view/:policyId`}
                    to={`/${config.appName}/policy/view/:policyId/policy-information`}
                  />
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ))}
                </Switch>
              </div>
            </PolicyDetailsProvider>
          </div>
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
};

export default PolicyView;

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { Button, Modal, Table, List, Alert } from 'antd';
import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import AddRole from './components/AddRole';
import RoleAction from './components/RoleAction';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';
import { splitUserDomain } from '../../../../../../services/utils/commonHandler';

class RolesTable extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    TimeAgo.addLocale(en);
    this.state = {
      data: {
        roles: [],
        count: null,
      },
      pagination: {},
      loading: false,
      selectedRows: [],
      userData: [],
      users: [],
      isEditRoleModalVisible: false,
      isUserListModalVisible: false,
    };
    const { t } = this.props;
    this.columns = [
      {
        title: t('label_roleName'),
        dataIndex: 'role',
        key: 'role',
        width: '30%',
      },
      {
        dataIndex: 'role',
        key: 'users',
        render: role => (
          <Button
            type="link"
            size="small"
            onClick={() => this.openUserListModal(role)}
          >
            {t('home_menu_users')}
          </Button>
        ),
      },
    ];
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedRows: selectedRows,
      });
    },
  };

  componentDidMount() {
    this.setPermittedActions();
    this.fetchRoles();
  }

  // Rerender component when parameters change
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)
    ) {
      const pagination = { ...this.state.pagination };
      pagination.page = 1;
      pagination.current = 1;
      this.setState({ pagination });
      this.fetchRoles(true, {}, this.props.filters);
    }
  }

  openUserListModal = username => {
    const { t } = this.props;
    let userDomain = splitUserDomain(username);
    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/roles/' +
      userDomain[0] +
      '?user-store=' +
      userDomain[1];

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            userData: res.data.data.users,
            isUserListModalVisible: true,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
      });
  };

  handleOk = e => {
    this.setState({
      isUserListModalVisible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      isUserListModalVisible: false,
    });
  };

  // fetch data from api
  fetchRoles = (params = {}, filters = {}) => {
    this.setState({ loading: true });
    const { t } = this.props;
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    let limit = 10;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: limit,
      'user-store': 'all',
      ...this.props.filters,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    let apiUrl =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/roles?' +
      encodedExtraParams;

    // send request to the invokerss
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          const roles = res.data.data.roles.map(role => ({ role: role }));
          this.setState({
            loading: false,
            data: {
              roles: roles,
              count: res.data.data.count,
            },
            pagination,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_roles') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchRoles({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  setPermittedActions = () => {
    const { t } = this.props;
    if (
      isAuthorized(this.config.scopes, [
        ['perm:roles:update', 'perm:roles:delete'],
      ])
    ) {
      this.columns.splice(1, 0, {
        title: t('label_actions'),
        dataIndex: 'role',
        key: 'action',
        width: '30%',
        render: (role, row) => (
          <span>
            <HtmlComments
              permission={'/permission/admin/device-mgt/roles/manage'}
            />
            <RoleAction data={role} row={row} fetchRoles={this.fetchRoles} />
          </span>
        ),
      });
    }
  };

  render() {
    const { data, pagination, loading } = this.state;
    const { t } = this.props;
    return (
      <div className={styles.tableContainer}>
        {!isAuthorized(this.config.scopes, [
          'perm:roles:update',
          'perm:roles:delete',
        ]) && (
            <div>
              <HtmlComments
                permission={'/permission/admin/device-mgt/roles/manage'}
              />
              <Alert
                message={t('noPerm_manageManage')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}
        <div style={{ background: '#f0f2f5' }}>
          <AddRole fetchRoles={this.fetchRoles} />
        </div>
        <div style={{ backgroundColor: '#ffffff', borderRadius: 5 }}>
          <Table
            columns={this.columns}
            rowKey="role"
            dataSource={data.roles}
            pagination={{
              ...pagination,
              size: 'small',
              total: data.count,
              disabled: loading,
              showTotal: (total, range) =>
                t('table_pagination', {
                  range1: range[0],
                  range2: range[1],
                  total: total,
                  data: t('home_menu_roles'),
                }),
            }}
            loading={loading}
            onChange={this.handleTableChange}
            rowSelection={this.rowSelection}
          />
        </div>
        <div>
          <Modal
            title={t('title_users')}
            width="900px"
            open={this.state.isUserListModalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            maskClosable={false}
          >
            <div>
              <List
                size="small"
                bordered
                dataSource={this.state.userData}
                renderItem={item => <List.Item>{item}</List.Item>}
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(RolesTable));

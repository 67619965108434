/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Divider,
  Modal,
  notification,
  Popconfirm,
  Tooltip,
  Typography,
  Col,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import GeoFenceForm from '../GeoFenceForm';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

const { Text } = Typography;
class GeoFenceActions extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isEditModalVisible: false,
    };
  }

  onEditGeoFence = e => {
    this.setState({
      isEditModalVisible: true,
    });
  };

  onCancelHandler = () => {
    this.setState({
      isEditModalVisible: false,
    });
  };

  onUpdateGeoFence = data => {
    const { t } = this.props;
    axios
      .put(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/geo-services/geo-fence/' +
        this.props.data.id,
        JSON.stringify(data),
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 201) {
          this.props.fetchGeoFenceData();
          this.setState({
            isEditModalVisible: false,
          });
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updateGeoFenceMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_updateError', { label: t('home_menu_geoFence') }),
          t,
        );
      });
  };

  onDeleteFence = () => {
    const { t } = this.props;
    let eventIds = this.props.data.eventConfig.map(obj => obj.id);
    axios
      .delete(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/geo-services/geo-fence/' +
        this.props.data.id +
        '?removeEventIds=' +
        eventIds,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.fetchGeoFenceData();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_deleteGeoFenceMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_deleteGeoFenceError'), t);
        this.setState({ loading: false });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <Col>
        <Tooltip placement="top" title={t('label_edit')}>
          <a onClick={this.onEditGeoFence}>
            <EditOutlined />
          </a>
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip placement="top" title={t('label_delete')}>
          <Popconfirm
            title={t('delete_confirmTxt')}
            onConfirm={this.onDeleteFence}
          >
            <a>
              <Text type="danger">
                <DeleteOutlined />
              </Text>
            </a>
          </Popconfirm>
        </Tooltip>
        <div>
          <Modal
            title={t('title_editGeoFence')}
            open={this.state.isEditModalVisible}
            onCancel={this.onCancelHandler}
            footer={null}
            width={900}
            maskClosable={false}
          >
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/platform-configurations/view'
              }
            />
            <GeoFenceForm
              action={'Update'}
              values={this.props.data}
              modalVisibility={this.state.isEditModalVisible}
              callback={values => this.onUpdateGeoFence(values)}
              groups={this.props.groups}
            />
          </Modal>
        </div>
      </Col>
    );
  }
}

export default withConfigContext(withTranslation()(GeoFenceActions));

/*
 * Copyright (C) 2022. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import styles from './styles.module.css';
import { Alert, Col, Result, Row, Table } from 'antd';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';

class BillingTable extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      start: this.props.location.state ? this.props.location.state.start : '',
      end: this.props.location.state ? this.props.location.state.end : '',
      tenantDomain: this.props.location.state
        ? this.props.location.state.tenantDomain
        : '',
      totalCostPerYear: this.props.location.state
        ? this.props.location.state.totalCostPerYear
        : 0,
      deviceCount: this.props.location.state
        ? this.props.location.state.deviceCount
        : 0,
      billPeriod: this.props.location.state
        ? this.props.location.state.billPeriod
        : '',
      loading: false,
      generateBill: false,
      billingData: this.props.location.state
        ? this.props.location.state.billingData
        : [],
      count: 0,
    };
  }

  componentDidMount() {
    // this.getAllBills();
  }

  getAllBills(params = {}) {
    const { t } = this.props;

    this.setState({ loading: true });

    if (moment.isMoment(this.state.start)) {
      this.setState({
        start: moment(this.state.start).format('YYYY-MM-DD HH:mm:ss'),
      });
    }
    if (moment.isMoment(this.state.end)) {
      this.setState({
        end: moment(this.state.end).format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    let extraParams;

    if (moment.isMoment(this.state.end) && moment.isMoment(this.state.start)) {
      extraParams = {
        tenantDomain: this.state.tenantDomain,
        startDate: moment(this.state.start).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(this.state.end).format('YYYY-MM-DD HH:mm:ss'),
        generateBill: this.state.generateBill,
      };
    } else {
      extraParams = {
        tenantDomain: this.state.tenantDomain,
        startDate: this.state.start,
        endDate: this.state.end,
        generateBill: this.state.generateBill,
      };
    }

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    axios
      .get(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/admin/devices/billing/file?' +
        encodedExtraParams,
      )
      .then(res => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            billingData:
              res.data.data.devices[res.data.data.devices.length - 1].device,
            count: res.data.data.count,
          });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        handleApiError(
          error,
          t('api_loadError', { label: t('label_Usage') }),
          t,
        );
      });
  }

  render() {
    const { t } = this.props;
    const columns = [
      {
        title: 'Device Identification',
        dataIndex: 'deviceIdentifier',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Date of Enrolment',
        dataIndex: 'enrolmentInfo',
        key: 'dateOfEnrolment',
        render: enrolmentInfo =>
          moment(enrolmentInfo.dateOfEnrolment).format('LLLL'),
      },
      {
        title: 'Status',
        dataIndex: 'enrolmentInfo',
        key: 'status',
        render: enrolmentInfo =>
          enrolmentInfo.status !== 'REMOVED'
            ? 'ENROLLED'
            : enrolmentInfo.status,
      },
      {
        title: 'Days Used',
        dataIndex: 'daysUsed',
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        render: cost => {
          return <span>{cost}$</span>;
        },
      },
    ];

    return !this.config.excludedBillingTenants.includes(
      this.config.userDomain,
    ) ? (
      <div
        className={styles.tableContainer}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: 5,
          margin: 40,
        }}
      >
        <div className={styles.content}>
          <Row>
            <Col style={{ marginRight: 30, marginBottom: 20 }}>
              <h2>Device Costs For {this.state.billPeriod}</h2>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginRight: 30, marginTop: 5 }}>
              <p>
                <b>Device Count : </b> {this.state.deviceCount}
              </p>
            </Col>
          </Row>
          <br />
        </div>
        {!isAuthorized(this.config.scopes, ['perm:admin:usage:view']) && (
          <div>
            <HtmlComments permission={'/device-mgt/admin/devices/usage/view'} />
            <Alert
              message={t('noPerm_manageBilling')}
              banner
              style={{ marginBottom: 15 }}
            />
          </div>
        )}
        <div>
          <Table
            columns={columns}
            className={styles.formattedTableItems}
            dataSource={this.state.billingData}
            pagination={{
              size: 'small',
              total: this.state.deviceCount,
              disabled: this.state.loading,
              showTotal: (total, range) =>
                t('table_pagination', {
                  range1: range[0],
                  range2: range[1],
                  total: total,
                  data: t('label_Usage'),
                }),
            }}
            loading={this.state.loading}
          />
        </div>
      </div>
    ) : (
      <Result
        status="403"
        title={t('noPerm_accessPageTitle')}
        subTitle={t('noPerm_contactSysAdmin')}
      />
    );
  }
}

export default withConfigContext(withTranslation()(BillingTable));

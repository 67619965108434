/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import React from 'react';
import { Button, Form, Modal, notification, Select, Tooltip } from 'antd';
import axios from 'axios';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import { withTranslation } from 'react-i18next';

class UpdateEnrollment extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      updateEnrollmentVisible: false,
      updatedOwnerName: null,
      data: [],
      pagination: {},
      loading: false,
      userData: [],
    };
  }

  componentDidMount() {
    this.loadUsersList();
  }

  loadUsersList = () => {
    const { t } = this.props;
    let apiURL =
      window.location.origin +
      this.config.serverConfig.invoker.uri +
      this.config.serverConfig.invoker.deviceMgt +
      '/users/list';
    axios
      .get(apiURL)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            userData: res.data.data.users,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_users') }),
          t,
        );
      });
  };

  updateEnrollmentOK = e => {
    const { t } = this.props;
    if (this.state.updatedOwnerName != null) {
      this.updateEnrollment(this.state.updatedOwnerName);
      this.setState({
        updateEnrollmentVisible: false,
      });
    } else {
      notification.error({
        message: t('label_there_was_a_problem'),
        duration: 0,
        description: t('label_error_enrollment_update'),
      });
    }
  };

  updateEnrollmentCancel = e => {
    this.setState({
      updateEnrollmentVisible: false,
    });
  };

  onUpdateEnrollment = value => {
    this.setState({ updatedOwnerName: value });
  };

  showDrawer = () => {
    this.setState({
      updateEnrollmentVisible: true,
    });
  };

  updateEnrollment = owner => {
    const { t } = this.props;
    const config = this.props.context;
    this.setState({ loading: true });
    const deviceData = this.props.selectedRows.map(obj => obj.deviceIdentifier);
    // send request to the invoker
    axios
      .put(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt +
        '/admin/devices/device-owner?owner=' +
        owner,
        JSON.stringify(deviceData),
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('label_done'),
            duration: 4,
            description: t('device_bulk_update_enrollment_success', {
              owner: owner,
            }),
          });
        }
        this.props.callback();
      })
      .catch(error => {
        handleApiError(error, t('label_error_enrollment_update'), t);
        this.setState({ loading: false });
      });
  };

  render() {
    const { userData } = this.state;
    const { t } = this.props;
    const userItems = userData.map((user, index) => (
      <Select.Option key={index} value={user.username}>
        {user.username}
      </Select.Option>
    ));
    return (
      <div>
        <Tooltip
          placement="bottom"
          title={
            !this.props.disabled
              ? t('label_update_enrollment')
              : t('device_bulk_update_enrollment_invalid_tooltip')
          }
        >
          <Button
            type="link"
            shape="circle"
            icon={<UserOutlined />}
            size={'default'}
            onClick={this.showDrawer}
            disabled={this.props.disabled}
          />
        </Tooltip>
        <Modal
          title={t('label_update_enrollment')}
          width="350px"
          open={this.state.updateEnrollmentVisible}
          okText={t('label_ok')}
          onOk={this.updateEnrollmentOK}
          onCancel={this.updateEnrollmentCancel}
          maskClosable={false}
        >
          <p>{t('device_bulk_update_enrollment_description')}</p>
          <Form.Item>
            <Select
              showSearch
              placeholder={t('label_input_owner')}
              onChange={this.onUpdateEnrollment}
            >
              {userItems}
            </Select>
          </Form.Item>
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(UpdateEnrollment));

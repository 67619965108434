/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  Alert,
  Divider,
  Modal,
  Button,
  Typography,
  notification,
  Popconfirm,
  Table,
  Tooltip,
  Result,
} from 'antd';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import BillingForm from '../BillingForm';
import { isAuthorized } from '../../../../../../../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { handleApiError } from '../../../../../../../../../../../../services/utils/errorHandler';

const { Text } = Typography;

class BillingTable extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.config = this.props.config;
    this.state = {
      editModalVisible: false,
      data: '',
      removeData: '',
    };
    this.openEditModal = this.openEditModal.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.updateBillData = this.updateBillData.bind(this);
    this.deleteMetaEntry = this.deleteMetaEntry.bind(this);
  }

  openEditModal = (id, row) => {
    this.setState({
      editModalVisible: true,
    });
  };

  deleteMetaEntry = data => {
    const { t } = this.props;

    let index = this.props.metaData.findIndex(function(obj) {
      if (obj.tenantDomain === data.tenantDomain) {
        return obj.tenantDomain === data.tenantDomain;
      }
    });

    this.props.metaData.splice(index, 1);

    const requestPayload = {
      dataType: 'STRING',
      metaKey: 'PER_DEVICE_COST',
      metaValue: JSON.stringify(this.props.metaData),
    };
    let apiUrl =
      window.location.origin +
      this.props.config.serverConfig.invoker.uri +
      this.props.config.serverConfig.invoker.deviceMgt +
      '/metadata/';
    axios
      .put(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 200) {
          this.props.callback();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_deleteBilling'),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_deleteError', { label: t('label_Billing') }),
          t,
        );
      });
  };

  handleEditCancel = e => {
    this.setState({
      editModalVisible: false,
    });
  };

  updateBillData = values => {
    const { t } = this.props;

    this.setState({
      editModalVisible: false,
    });

    let index = this.props.metaData.findIndex(function(obj) {
      if (obj.tenantDomain === values.tenantDomain) {
        return obj.tenantDomain === values.tenantDomain;
      }
    });

    this.props.metaData[index] = values;

    const requestPayload = {
      dataType: 'STRING',
      metaKey: 'PER_DEVICE_COST',
      metaValue: JSON.stringify(this.props.metaData),
    };
    let apiUrl =
      window.location.origin +
      this.props.config.serverConfig.invoker.uri +
      this.props.config.serverConfig.invoker.deviceMgt +
      '/metadata/';
    axios
      .put(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 200) {
          this.props.callback();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_updateBilling'),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_updateError', { label: t('label_Billing') }),
          t,
        );
      });
  };

  render() {
    const { t } = this.props;
    const data = this.props.metaData;
    const loading = this.props.loading;
    const columns = [
      {
        title: 'Tenant Domain',
        dataIndex: 'tenantDomain',
        width: 100,
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        width: 100,
      },
      {
        title: 'Actions',
        dataIndex: 'tenantId',
        width: 100,
        render: (id, row) => (
          <span>
            <span>
              <Tooltip placement="top" title={t('label_editBill')}>
                <a>
                  <EditOutlined
                    onClick={
                      (this.openEditModal = () => {
                        {
                          this.setState({
                            editModalVisible: true,
                          });
                          this.setState({ data: row });
                        }
                      })
                    }
                  />
                </a>
              </Tooltip>
            </span>
            <span>
              <Divider type="vertical" />
              <Tooltip placement="bottom" title={t('label_deleteBill')}>
                <Popconfirm
                  placement="top"
                  title={t('label_areYouSure')}
                  onConfirm={
                    (this.delete = () => {
                      {
                        this.setState({ removeData: row });
                        this.deleteMetaEntry(row);
                      }
                    })
                  }
                  okText={t('label_ok')}
                  cancelText={t('label_cancel')}
                >
                  <a>
                    <Text type="danger">
                      <DeleteOutlined />
                    </Text>
                  </a>
                </Popconfirm>
              </Tooltip>
            </span>
          </span>
        ),
      },
    ];

    if (data[0] !== null) {
      return (
        <div>
          {!isAuthorized(this.props.config.scopes, [
            'perm:metadata:view',
            'perm:metadata:create',
            'perm:metadata:update',
          ]) && (
            <div>
              <HtmlComments
                permission={
                  '/permission/admin/device-mgt/metadata/view OR\n' +
                  '/permission/admin/device-mgt/metadata/create OR\n' +
                  '/permission/admin/device-mgt/metadata/update'
                }
              />
              <Alert
                message={t('noPerm_manageBilling')}
                banner
                style={{ marginBottom: 15 }}
              />
            </div>
          )}

          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            bordered
          />
          <div>
            <Modal
              title={t('label_updateBill')}
              open={this.state.editModalVisible}
              onCancel={this.handleEditCancel}
              footer={null}
              maskClosable={false}
            >
              <BillingForm
                config={this.props.config}
                action={'Edit'}
                modalVisibility={this.state.editModalVisible}
                callback={values => this.updateBillData(values)}
                values={this.state.data}
              />
            </Modal>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Result
          status="404"
          title={t('noBilling_Txt')}
          subTitle={t('createNewBilling_Txt')}
          extra={
            <Button type="primary" onClick={this.props.handleAdd}>
              Add a New Billing
            </Button>
          }
        />
      </div>
    );
  }
}

export default withTranslation()(BillingTable);

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import axios from 'axios';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Result, Table } from 'antd';
import TimeAgo from 'javascript-time-ago';
import { Link } from 'react-router-dom';

// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

let config = null;

const columns = t => {
  return [
    {
      title: t('label_device'),
      dataIndex: 'deviceName',
      width: 100,
      sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
    },
    {
      title: t('label_type'),
      dataIndex: 'deviceType',
      key: 'type',
      // eslint-disable-next-line react/display-name
      render: type => {
        const defaultPlatformIcons = config.defaultPlatformIcons;
        let icon = defaultPlatformIcons.default.icon;
        let color = defaultPlatformIcons.default.color;
        let theme = defaultPlatformIcons.default.theme;

        if (defaultPlatformIcons.hasOwnProperty(type)) {
          icon = defaultPlatformIcons[type].icon;
          color = defaultPlatformIcons[type].color;
          theme = defaultPlatformIcons[type].theme;
        }

        return (
          <span style={{ fontSize: 20, color: color, textAlign: 'center' }}>
            <LegacyIcon type={icon} theme={theme} />
          </span>
        );
      },
    },
    {
      title: t('label_description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('label_action'),
      dataIndex: 'id',
      key: 'action',
      render: (id, row) => {
        if (row.description === 'Chat Request') {
          return (
            <Link
              to={`/${config.serverConfig.platform}/devices/${row.deviceType}/${row.deviceIdentifier}/remote-session`}
            >
              {t('label_chat')}
            </Link>
          );
        }
      },
    },
  ];
};

class NotificationsTable extends React.Component {
  constructor(props) {
    super(props);
    config = this.props.context;
    TimeAgo.addLocale(en);
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      selectedRows: [],
      paramsObj: {},
    };
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedRows: selectedRows,
      });
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  // Rerender component when parameters change
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.notificationType !== this.props.notificationType) {
      this.fetchData();
    }
  }

  // fetch data from api
  fetchData = (params = {}) => {
    const { t } = this.props;
    // const policyReportData = this.props;
    this.setState({ loading: true });
    // get current page
    const currentPage = params.hasOwnProperty('page') ? params.page : 1;

    let extraParams;

    extraParams = {
      offset: 10 * (currentPage - 1), // calculate the offset
      limit: 10,
    };

    const encodedExtraParams = Object.keys(extraParams)
      .map(key => key + '=' + extraParams[key])
      .join('&');

    let apiUrl;

    if (this.props.notificationType === 'unread') {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/notifications?status=NEW&' +
        encodedExtraParams;
    } else {
      apiUrl =
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/notifications?' +
        encodedExtraParams;
    }

    // send request to the invoker
    axios
      .get(apiUrl)
      .then(res => {
        if (res.status === 200) {
          const pagination = { ...this.state.pagination };
          this.setState({
            loading: false,
            data: res.data.data,
            pagination,
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_loadError', { label: t('home_menu_devices') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchData({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  render() {
    let { data, pagination, loading } = this.state;
    const { t } = this.props;
    return (
      <div className={styles.tableContainer}>
        {data.count > 0 && (
          <Table
            columns={columns(t)}
            rowKey={record => record.id}
            dataSource={data.notifications}
            pagination={{
              ...pagination,
              size: 'small',
              // position: "top",
              total: data.count,
              disabled: loading,
              showTotal: (total, range) =>
                t('table_pagination', {
                  range1: range[0],
                  range2: range[1],
                  total: total,
                  data: t('label_notifications'),
                }),
            }}
            loading={loading}
            onChange={this.handleTableChange}
            rowSelection={this.rowSelection}
          />
        )}
        {data.count === 0 && (
          // todo Change Result icon #issue592
          <Result status="404" title={t('noNotification_Txt')} />
        )}
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(NotificationsTable));

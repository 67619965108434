/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import { RoutesContext } from "../../index";
import React, { useContext, useEffect, useState } from "react";
import { Col, Result, Row } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { withConfigContext } from "../../../../components/ConfigContext";
import styles from "./styles.module.css";
import Authorized from "../../../../components/Authorized";
import { withTranslation } from "react-i18next";
import BreadcrumbComponent from "../../../../components/BreadCrumb";
import { subDays } from "date-fns";
import IframeWrapper from "../../components/IframeWrapper";
import { getUnixTime } from "../../../../services/utils/functions";
import configs from "../../../../../public/conf/config.json";
import { useSelector } from "react-redux";
import HtmlComments from "../../../../components/ReactHtmlComments/HtmlComments";

const OtherDevicesUPS = (props) => {
  const config = props.context;
  const { t } = props;
  const routesContext = useContext(RoutesContext);
  const rightNow = new Date();
  const [range, setRange] = useState({
    from: getUnixTime(subDays(rightNow, 2)),
    to: getUnixTime(rightNow)
  });
  const [deviceId, setdeviceIdValue] = useState("");
  const [outsidedeviceId, setOutsidedeviceId] = useState(false);
  const [outsideDates, setOutsideDates] = useState(false);
  const [value, setValue] = useState("");
  const [dashboardUrl2, setDashboardUrl2] = useState("");
  const selectedBranch = useSelector((state) => state.branch.value);

  useEffect(() => {
    // Use the context to set the current route
    routesContext.setCurrentRoute("allOtherDevicesUPS");
    setDashboardUrl2(`${configs.grafanaConfig.UPSSensors}&var-branch=${selectedBranch}`);
  }, [deviceId, range.from, range.to, selectedBranch]);

  useEffect(() => {
    const str = window.location.search;
    if (str) {
      const urlParams = new URLSearchParams(window.location.search);
      const paramdeviceId = urlParams.get("deviceId");
      const paramFrom = urlParams.get("from");
      const paramTo = urlParams.get("to");
      if (paramdeviceId !== null) {
        setdeviceIdValue(paramdeviceId);
        setOutsidedeviceId(true);
      }
      if (paramFrom !== null && paramTo !== null) {
        setRange({ from: paramFrom, to: paramTo });
        setOutsideDates(true);
      }
    }
    // eslint-disable-next-line
  }, [t]);

  const routes = (appName) => [
    {
      path: `/${appName}`,
      breadcrumbName: t("home_menu_home")
    },
    {
      breadcrumbName: t("home_menu_other_devices")
    },
    {
      breadcrumbName: t("home_menu_other_devices_ups")
    }
  ];

  return (
    <div>
      <HtmlComments
        permission={
          '/device-mgt/grafana/view'
        }
      />
      <Authorized
        scope={['grafana:api:view']}
        yes={
          <div>
            <PageHeader
              className={styles.pageHeader}
              ghost={false}
              breadcrumb={
                <BreadcrumbComponent
                  breadcrumbList={routes(config.appName, t)}
                />
              }
            >
            </PageHeader>
            <Row gutter={12}>
              <Col span={24}>
                <div className={styles.iframeContainer}>
                  <IframeWrapper
                    iframeSrc={dashboardUrl2}
                    height={"700px"}
                  />
                </div>
              </Col>
            </Row>
          </div>
        }
        no={
          <Result
            status="403"
            title={t("noPerm_accessPageTitle")}
            subTitle={t("noPerm_contactSysAdmin")}
          />
        }
      />
    </div>
  );
};

export default withConfigContext(withTranslation()(OtherDevicesUPS));

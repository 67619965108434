/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import {
  CloseOutlined,
  DeleteOutlined,
  SettingOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import {
  Button,
  Tooltip,
  Popconfirm,
  Drawer,
  notification,
  Row,
  Col,
} from 'antd';
import UpdateEnrollment from './components/UpdateEnrollment';
import DeviceOperations from '../../../DeviceOperations';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import AddToGroup from './components/AddToGroup';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';
import HtmlComments from '../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class BulkActionBar extends React.Component {
  deviceType;
  deviceIdentifiers;
  ownerships;

  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      selectedMultiple: false,
      selectedSingle: false,
      isOperationsVisible: false,
      canDelete: true,
      deviceGroups: [],
      groupModalVisible: false,
      selectedGroupId: [],
    };
  }

  // This method checks whether NON-REMOVED devices are selected
  onDeleteDeviceCall = () => {
    let tempDeleteState;
    for (let i = 0; i < this.props.selectedRows.length; i++) {
      if (this.props.selectedRows[i].enrolmentInfo.status !== 'REMOVED') {
        tempDeleteState = false;
        break;
      }
      tempDeleteState = true;
    }
    this.setState({ canDelete: tempDeleteState });
  };

  onConfirmDelete = () => {
    const { t } = this.props;
    if (this.state.canDelete) {
      const config = this.props.context;

      const deviceData = this.props.selectedRows.map(
        obj => obj.deviceIdentifier,
      );

      // send request to the invoker
      axios
        .put(
          window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          '/admin/devices/permanent-delete',
          deviceData,
          { headers: { 'Content-Type': 'application/json' } },
        )
        .then(res => {
          if (res.status === 200) {
            this.props.callback();
          }
        })
        .catch(error => {
          handleApiError(error, t('label_error_delete_devices'), t);
        });
    }
  };

  onConfirmDisenroll = () => {
    const config = this.props.context;
    const { t } = this.props;
    const deviceData = this.props.selectedRows[0];

    // send request to the invoker
    axios
      .delete(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt +
        '/devices/type/' +
        deviceData.type +
        '/id/' +
        deviceData.deviceIdentifier,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('label_done'),
            duration: 4,
            description: t('label_success_dis_enrollment_device'),
          });
          this.props.callback();
        }
      })
      .catch(error => {
        handleApiError(error, t('label_error_dis_enrollment_device'), t);
      });
  };

  onAddOperations = () => {
    const { t } = this.props;
    const uniqueDeviceTypes = [
      ...new Set(this.props.selectedRows.map(obj => obj.type)),
    ];
    if (uniqueDeviceTypes.length > 1) {
      notification.error({
        message: t('label_there_was_a_problem'),
        duration: 0,
        description: t('label_multiple_device_types_selected'),
      });
    } else {
      this.deviceType = uniqueDeviceTypes.toString();
      this.deviceIdentifiers = this.props.selectedRows.map(
        obj => obj.deviceIdentifier,
      );
      this.ownerships = this.props.selectedRows.map(
        obj => obj.enrolmentInfo.ownership,
      );
      this.setState({
        isOperationsVisible: true,
      });
    }
  };

  onOperationsClose = () => {
    this.setState({
      isOperationsVisible: false,
    });
  };

  onConfirmDeviceRemove = () => {
    const config = this.props.context;

    const deviceData = this.props.selectedRows.map(obj => {
      let groupDevice = {
        id: obj.deviceIdentifier,
        type: obj.type,
      };
      return groupDevice;
    });

    console.log(deviceData);
    // send request to the invoker
    axios
      .post(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        `/groups/id/${this.props.groupId}/devices/remove`,
        deviceData,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 200) {
          this.props.callback();
        }
      })
      .catch(error => {
        const { t } = this.props;
        handleApiError(error, t('label_error_remove_device_from_group'), t);
      });
  };

  render() {
    const isSelected = this.props.selectedRows.length > 0;
    const isSelectedSingle = this.props.selectedRows.length === 1;
    const { t } = this.props;

    return (
      <div>
        <div style={{ width: '20%', marginBottom: 10 }}>
          <Row>
            {this.props.isGroupRemoverEnable && (
              <Col span={4}>
                <Tooltip
                  placement="bottom"
                  title={
                    isSelected
                      ? t('label_removeDevice')
                      : t('label_select_devices_need_to_remove')
                  }
                >
                  <Popconfirm
                    placement="topLeft"
                    title={t('label_are_you_sure')}
                    onConfirm={this.onConfirmDeviceRemove}
                    okText={t('label_ok')}
                    cancelText={t('label_cancel')}
                    disabled={!isSelected}
                  >
                    <Button
                      type="link"
                      shape="circle"
                      icon={<ClearOutlined />}
                      size={'default'}
                      disabled={!isSelected}
                      style={{ margin: '2px' }}
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
            )}
            {isAuthorized(this.config.scopes, [
              'perm:devices:permanent-delete',
            ]) && (
                <Col span={4}>
                  <Tooltip
                    placement="bottom"
                    title={
                      isSelected
                        ? t('label_deleteDevice')
                        : t('device_deleteDevice_invalid_tooltip')
                    }
                    autoAdjustOverflow={true}
                  >
                    <HtmlComments
                      permission={
                        '/permission/admin/device-mgt/admin/devices/permanent-delete'
                      }
                    />
                    <Popconfirm
                      placement="topLeft"
                      title={
                        this.state.canDelete
                          ? t('device_deleteDevice_confirmTxt')
                          : t('device_deleteDevice_invalidTxt')
                      }
                      onConfirm={this.onConfirmDelete}
                      okText={t('label_ok')}
                      cancelText={t('label_cancel')}
                      disabled={!isSelected}
                    >
                      <Button
                        type="link"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size={'default'}
                        onClick={this.onDeleteDeviceCall}
                        disabled={!isSelected}
                        style={{ margin: '2px' }}
                      />
                    </Popconfirm>
                  </Tooltip>
                </Col>
              )}
            <Col span={4}>
              <Tooltip
                placement="bottom"
                title={
                  isSelectedSingle
                    ? t('label_disenrollDevice')
                    : t('device_disenrollDevice_invalid_tooltip')
                }
              >
                <Popconfirm
                  placement="topLeft"
                  title={t('label_are_you_sure')}
                  onConfirm={this.onConfirmDisenroll}
                  okText={t('label_ok')}
                  cancelText={t('label_cancel')}
                  disabled={!isSelectedSingle}
                >
                  <Button
                    type="link"
                    shape="circle"
                    icon={<CloseOutlined />}
                    size={'default'}
                    style={{
                      margin: '2px',
                    }}
                    disabled={!isSelectedSingle}
                  />
                </Popconfirm>
              </Tooltip>
            </Col>
            <Col span={4}>
              <AddToGroup
                selectedRows={this.props.selectedRows}
                callback={this.props.callback}
                disabled={!isSelected}
              />
            </Col>
            <Col span={4}>
              <Tooltip
                placement="bottom"
                title={
                  isSelected
                    ? t('label_addOperations')
                    : t('device_addOperations_invalid_tooltip')
                }
              >
                <Button
                  type="link"
                  shape="circle"
                  icon={<SettingOutlined />}
                  size={'default'}
                  onClick={this.onAddOperations}
                  style={{ margin: '2px' }}
                  disabled={!isSelected}
                />
              </Tooltip>
            </Col>
            {isAuthorized(this.config.scopes, [
              'perm:admin:devices:update-enrollment',
            ]) && (
                <Col span={4}>
                  <HtmlComments
                    permission={
                      '/permission/admin/device-mgt/admin/devices/update-enrollment'
                    }
                  />
                  <UpdateEnrollment
                    disabled={!isSelected}
                    selectedRows={this.props.selectedRows}
                    callback={this.props.callback}
                  />
                </Col>
              )}
          </Row>
        </div>
        <Drawer
          title={t('label_operations')}
          placement="right"
          closable={true}
          onClose={this.onOperationsClose}
          open={this.state.isOperationsVisible}
          width={360}
        >
          <div>
            {this.state.isOperationsVisible && (
              <DeviceOperations
                deviceType={this.deviceType}
                ownerships={this.ownerships}
                deviceIdentifiers={this.deviceIdentifiers}
                idType={'device'}
              />
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(BulkActionBar));

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Modal, notification } from 'antd';
import { withTranslation } from 'react-i18next';
import BillingForm from '../BillingForm';
import HtmlComments from '../../../../../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withConfigContext } from '../../../../../../../../../../../../components/ConfigContext';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../../../../../services/utils/errorHandler';

class AddBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalVisible: false,
    };
  }

  componentDidMount() {
    this.changeModalVisibility();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.addModalVisible === false) {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility = () => {
    this.setState({
      addModalVisible: true,
    });
  };

  addBill = values => {
    const { t } = this.props;
    this.props.metaData.push(values);

    const requestPayload = {
      dataType: 'STRING',
      metaKey: 'PER_DEVICE_COST',
      metaValue: JSON.stringify(this.props.metaData),
    };
    let apiUrl =
      window.location.origin +
      this.props.config.serverConfig.invoker.uri +
      this.props.config.serverConfig.invoker.deviceMgt +
      '/metadata/';
    axios
      .put(apiUrl, requestPayload, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(res => {
        if (res.status === 200) {
          this.props.callback();
          this.props.closeAddBillModal();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_addBilling'),
          });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        handleApiError(
          error,
          t('api_addError', { label: t('label_Billing') }),
          t,
        );
      });
  };

  hideBillAddingModal = () => {
    this.setState({
      addModalVisible: false,
    });
  };

  fetchNewBillData = values => {
    this.setState({
      addModalVisible: false,
    });
    this.addBill(values);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Modal
            title={t('title_addNewBilling')}
            open={this.state.addModalVisible}
            onCancel={this.hideBillAddingModal}
            footer={null}
            maskClosable={false}
          >
            <HtmlComments
              permission={'/permission/admin/device-mgt/metadata/create'}
            />
            <BillingForm
              config={this.props.config}
              action={'Add'}
              modalVisibility={this.state.addModalVisible}
              callback={values => this.fetchNewBillData(values)}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AddBill));

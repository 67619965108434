/*
 * Copyright (C) 2018 - 2023 Entgra (Pvt) Ltd, Inc - All Rights Reserved.
 *
 * Unauthorised copying/redistribution of this file, via any medium is strictly prohibited.
 *
 * Licensed under the Entgra Commercial License, Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *       https://entgra.io/licenses/entgra-commercial/1.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { Button, Input, notification, Modal, Descriptions } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import { isAuthorized } from '../../../../../../services/utils/authorizationHandler';

class EditDevice extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    const defaultDescription = {
      properties: {
        // "max_import_kvar": "",
        // "min_frequency": "",
        // "max_frequency": "",
        "current_a_max": "",
        "current_b_max": "",
        "current_c_max": "",
        "power_factor_min": "",
        "max_import_kva": "",
      }
    };

    let initialDescription = this.props.device.description;
    if (
        !initialDescription ||
      initialDescription === '{}' ||
      initialDescription === 'string'
    ) {
      initialDescription = JSON.stringify(defaultDescription);
    } else {
      let parsedInitialDescription = JSON.parse(initialDescription);

      // Ensure parsedInitialDescription has properties
      if (!parsedInitialDescription.properties) {
        parsedInitialDescription.properties = {};
      }

      // Add missing keys from defaultDescription to initialDescription
      Object.keys(defaultDescription.properties).forEach((key) => {
        if (
          !Object.prototype.hasOwnProperty.call(
            parsedInitialDescription.properties,
            key,
          )
        ) {
          parsedInitialDescription.properties[key] = '';
        }
      });

      initialDescription = JSON.stringify(parsedInitialDescription);
    }
    this.state = {
      visible: false,
      updatedDescription: initialDescription
    };
  }

  deviceName = this.props.device ? this.props.device.name : '';
  updatedProperties = this.props.device.properties;

  onBlurDeviceNameInput = e => {
    this.deviceName = e.target.value;
  };

  onPropertyChange = (e, propertyName) => {
    if (propertyName === 'description') {
      this.setState({ updatedDescription: e.target.value });
    } else {
      // Update the value of the property in the updatedProperties object
      this.updatedProperties = this.updatedProperties.map(property => {
        if (property.name === propertyName) {
          return { ...property, value: e.target.value };
        }
        return property;
      });
    }
  };

  onDescriptionPropertyChange = (e, propertyName) => {
    let { updatedDescription } = this.state;
    const parsedDescription = JSON.parse(updatedDescription);

    parsedDescription.properties[propertyName] = e.target.value;

    updatedDescription = JSON.stringify(parsedDescription);
    this.setState({ updatedDescription });
  };

  onClickUpdateButton = () => {
    this.onConfirmUpdate();
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  onConfirmUpdate = () => {
    const config = this.props.context;
    const { t } = this.props;

    const payload = {
      name: this.deviceName,
      description: this.state.updatedDescription,
      properties: this.updatedProperties
    };
    // send request to the invoker
    axios
      .put(
        window.location.origin +
          config.serverConfig.invoker.uri +
          config.serverConfig.invoker.deviceMgt +
          '/device/agent/enroll/' +
          this.props.device.type +
          '/' +
          this.props.device.serial,
        payload
      )
      .then(res => {
        if (res.status === 202) {
          this.props.callback();
          notification.success({
            message: t('label_done'),
            duration: 4,
            description: t('form_update_notification')
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('form_update_notification_errors'), t);
      });
  };

  render() {
    const { device } = this.props;
    const { t } = this.props;
    const description = JSON.parse(this.state.updatedDescription || '{}');
    const propertyLabels = {
      // max_import_kvar: 'Max Import KVAR',
      // min_frequency: 'Min Frequency',
      // max_frequency: 'Max Frequency',
      current_a_max: 'Max Current A',
      current_b_max: 'Max Current B',
      current_c_max: 'Max Current C',
      power_factor_min: 'Min Power Factor',
      max_import_kva: 'Max Import KVA',
    };

    const excludedKeys = ['max_import_kvar', 'min_frequency', 'max_frequency'];
    const isAuthorizedToModifyDevice =  isAuthorized(this.config.scopes, [['dm:device:modify']]);
    return (
      <>
        <Button
          type="link"
          shape="circle"
          icon={isAuthorizedToModifyDevice ? <EditOutlined /> : <EyeOutlined />}
          size={'default'}
          style={{ margin: '2px' }}
          onClick={this.handleVisibleChange}>
        </Button>

        <Modal
          open={this.state.visible}
          title={t('form_updateDeviceProperties')}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              {t('label_cancel')}
            </Button>,
            isAuthorizedToModifyDevice &&(
              <Button
                key="update"
                type="primary"
                onClick={this.onClickUpdateButton}
              >
                {t('label_update')}
              </Button>
            )
          ]}
        >
          <div style={{ alignItems: 'right' }}>
            <Descriptions column={1} size="small" bordered>
              <Descriptions.Item label={t('label_deviceName')}>
                <Input
                  defaultValue={device ? device.name : ''}
                  onChange={this.onBlurDeviceNameInput}
                  autoFocus
                  disabled={!isAuthorizedToModifyDevice}
                />
              </Descriptions.Item>
              {Object.entries(
                (description && description.properties) || {}
              )
                .filter(([key]) => !excludedKeys.includes(key))
                .map(([key, value]) => (
                <Descriptions.Item key={key} label={propertyLabels[key] || key}>
                  <Input
                    defaultValue={value}
                    onChange={e => this.onDescriptionPropertyChange(e, key)}
                    disabled={!isAuthorizedToModifyDevice}
                  />
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>
        </Modal>
      </>
    );
  }
}

export default withConfigContext(withTranslation()(EditDevice));

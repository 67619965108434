/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useEffect, useRef } from 'react';
import { withConfigContext } from '../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';
import { Select } from 'antd';
import styles from '../../styles.module.css';
import { updateBranch } from '../../../../features/api/branchSlice';
import { BRANCHES } from '../../../../constant/TableData.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateDefaultDevice,
  updateDeviceOptions,
} from '../../../../features/api/deviceOptionSlice';
import { isEmpty, isEqual } from 'lodash';
import { handleApiError } from '../../../../services/utils/errorHandler';
import axios from 'axios';
import configs from '../../../../../public/conf/config.json';

const BranchDropdown = (props) => {
  const { t } = props;
  const branches = props.branches;
  const dispatch = useDispatch();
  const options = Object.keys(branches)
    .filter((branchKey) => branchKey === '01' || branchKey === '02')
    .map((branchKey) => ({
      value: branchKey,
      label: BRANCHES[branchKey].name,
    }));

  useEffect(() => {
    const options = Object.keys(branches)
      .filter((branchKey) => branchKey === '01' || branchKey === '02')
      .map((branchKey) => ({
        value: branchKey,
        label: BRANCHES[branchKey].name,
      }));
    if (options.length > 0) {
      dispatch(updateBranch(options[0].value));
    }
  }, [branches]);

  const selectedBranch = useSelector((state) => state.branch.value);
  const deviceOptions = useSelector(
    (state) => state.deviceOption.deviceOptions,
  );
  const previousBranch = useRef(null);

  const handleBranchChange = (branch) => {
    if (branch.length !== 0) {
      dispatch(updateBranch(branch));
    }
  };

  useEffect(() => {
    const fetchMeters = async () => {
      if (isEmpty(deviceOptions)) {
        await getMeters({ branch: selectedBranch });
      } else if (!isEmpty(deviceOptions) && !isEqual(previousBranch.current, selectedBranch)) {
        await getMeters({ branch: selectedBranch });
      }
      previousBranch.current = selectedBranch; // Update previous branch reference
    };

    fetchMeters();
  }, [selectedBranch, deviceOptions]);

  const getMeters = (params = {}) => {
    const currentPage = 1;

    let limit = 20;
    if (params.results) {
      limit = params.results;
    }

    const extraParams = {
      offset: limit * (currentPage - 1),
      limit: limit,
      ...params,
    };

    let encodedExtraParams = Object.keys(extraParams)
      .map((key) => key + '=' + extraParams[key])
      .join('&');

    axios
      .get(
        window.location.origin +
          configs.serverConfig.invoker.uri +
          configs.serverConfig.invoker.meterMgt +
          '/devices?requireDeviceInfo=true&type=power-meter&' +
          encodedExtraParams,
      )
      .then((res) => {
        const metersData = res.data.data.devices
          .map((device) => ({
            serial: device.deviceIdentifier,
            name: device.name,
            branch:
              device.properties?.find((prop) => prop.name === 'BRANCH')
                ?.value || '-',
            state: device.enrolmentInfo.status,
            subTypeId: device.properties?.find(
              (prop) => prop.name === 'SUB_TYPE_ID',
            )?.value,
          }))
          .filter((device) => device.subTypeId !== '103');

        const updatedOptions = [
          { value: 'All', label: 'All' },
          ...metersData.map((device) => ({
            label: device.name,
            value: device.serial,
          })),
        ];
        dispatch(updateDeviceOptions(updatedOptions));
        if (selectedBranch.toString() === '01') {
          dispatch(updateDefaultDevice('373224020010'));
        } else if (selectedBranch.toString() === '02') {
          dispatch(updateDefaultDevice('373224020001'));
        }
      })
      .catch((error) => {
        handleApiError(error, t('api_getMeterError'), t);
      });
  };

  return (
    <Select
      showSearch
      filterOption={(input, option) =>
        option.props.label
          .toString()
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0 ||
        option.props.value
          .toString()
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      className={styles.otherSearchBar}
      placeholder={t('please_select')}
      defaultValue={'00'}
      value={selectedBranch}
      onChange={handleBranchChange}
      options={options}
    />
  );
};

export default withConfigContext(withTranslation()(BranchDropdown));

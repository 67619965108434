/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import moment from 'moment';
import {
  Spin,
  DatePicker,
  Row,
  Col,
  Button,
  Typography,
  Result,
  Radio,
  Tooltip,
} from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../../../components/ConfigContext';
import styles from './styles.module.css';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import LocationHistoryMap from '../LocationHistoryMap';
import { handleApiError } from '../../../../../../services/utils/errorHandler';
import { withTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const { Text } = Typography;

let start = '';
let end = '';

class GeoDashboard extends React.Component {
  constructor(props) {
    super(props);
    end = moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
        new Date().getMilliseconds(),
      ),
    );
    end = end.add(2, 'hours');
    start = moment().startOf('day');
    start = start.add(-1, 'days');
    const { t } = this.props;
    this.state = {
      deviceData: [],
      selectedDevice: '',
      locationHistorySnapshots: [],
      groupLocationHistorySnapshots: [],
      loading: false,
      start: start,
      end: end,
      buttonTooltip: t('label_fetchLocations'),
      isButtonEnable: false,
      isError: false,
      viewType: 'plain',
      enabledViews: {
        plainViewEnabled: true,
        timestampViewEnabled: false,
        locationPointsViewEnabled: false,
        animatedViewEnabled: false,
      },
    };
  }

  componentDidMount() {
    if (this.props.deviceIdentifier) {
      this.fetchLocationHistory();
    } else {
      this.fetchGroupLocationHistory();
    }
  }
  /**
   * Call back on apply button in the date time picker
   */
  onChangeDateRange = (dates, dateString) => {
    this.setState({
      start: dateString[0],
      end: dateString[1],
      isButtonEnable: true,
    });
  };

  fetchGroupLocationHistory = () => {
    const toInMills = moment(this.state.end);
    const fromInMills = moment(this.state.start);
    const config = this.props.context;
    this.setState({ loading: true });
    const { t } = this.props;
    if (fromInMills > toInMills) {
      this.setState({
        isError: true,
      });
      return;
    }

    axios
      .get(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/devices/' +
        this.props.groupId +
        '/location-history?' +
        'from=' +
        fromInMills +
        '&to=' +
        toInMills +
        '&type=path',
      )
      .then(res => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            isButtonEnable: false,
            groupLocationHistorySnapshots: res.data.data.devices,
            isError: false,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_loadGroupDeviceLocationsDetailsError'), t);
        this.setState({ loading: false });
      });
  };

  fetchLocationHistory = () => {
    const toInMills = moment(this.state.end);
    const fromInMills = moment(this.state.start);
    const config = this.props.context;
    const { t } = this.props;
    this.setState({ loading: true });

    if (fromInMills > toInMills) {
      this.setState({
        isError: true,
      });
      return;
    }
    axios
      .get(
        window.location.origin +
        config.serverConfig.invoker.uri +
        config.serverConfig.invoker.deviceMgt+
        '/devices/' +
        this.props.deviceType +
        '/' +
        this.props.deviceIdentifier +
        '/location-history?' +
        'from=' +
        fromInMills +
        '&to=' +
        toInMills +
        '&type=path',
      )
      .then(res => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            isButtonEnable: false,
            locationHistorySnapshots: res.data.data.pathSnapshot,
            isError: false,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('api_retrieveDeviceLocationsDetailsError'), t);
        this.setState({ loading: false });
      });
  };

  onChangePlainViewToggle = value => {
    let enabledViews = { ...this.state.enabledViews };
    enabledViews.plainViewEnabled = value;
    this.setState({ enabledViews });
  };

  onChangeTimestampViewToggle = value => {
    let enabledViews = { ...this.state.enabledViews };
    enabledViews.timestampViewEnabled = value;
    this.setState({ enabledViews });
  };

  onChangeLocationPointsViewToggle = value => {
    let enabledViews = { ...this.state.enabledViews };
    enabledViews.locationPointsViewEnabled = value;
    this.setState({ enabledViews });
  };

  onChangeAnimatedViewToggle = value => {
    let enabledViews = { ...this.state.enabledViews };
    enabledViews.animatedViewEnabled = value;
    this.setState({ enabledViews });
  };

  /**
   * Geo Dashboard controller
   */
  controllerBar = () => {
    const { t } = this.props;
    return (
      <div className={styles.controllerDiv}>
        <Row>
          <Col className={styles.col}>
            <RangePicker
              defaultValue={[this.state.start, this.state.end]}
              showTime
              onChange={this.onChangeDateRange}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={
                this.props.deviceIdentifier
                  ? this.fetchLocationHistory
                  : this.fetchGroupLocationHistory
              }
              disabled={!this.state.isButtonEnable}
            />
          </Col>
          <Col flex="auto">
            <div style={{ float: 'right' }}>
              <Radio.Group
                defaultValue="plain"
                buttonStyle="solid"
                onChange={this.handleModeChange}
              >
                <Radio.Button value="plain">
                  {t('label_plainView')}{' '}
                  {
                    <Tooltip title={t('geoHistoryPlainView_infoTxt')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                </Radio.Button>
                <Radio.Button value="timestamp">
                  {t('label_timestampView')}{' '}
                  {
                    <Tooltip title={t('geoHistoryTimestampsView_infoTxt')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                </Radio.Button>
                <Radio.Button value="loactionPoint">
                  {t('label_locationPointView')}{' '}
                  {
                    <Tooltip title={t('geoHistoryLocationPointsView_infoTxt')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                </Radio.Button>
                <Radio.Button value="animated">
                  {t('label_animatedView')}{' '}
                  {
                    <Tooltip title={t('geoHistoryAnimatedView_infoTxt')}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                </Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        </Row>
        {this.state.isError && (
          <div>
            <Text type={'danger'}>{t('historySnapshot_errorTxt')}</Text>
          </div>
        )}
      </div>
    );
  };

  handleModeChange = value => {
    console.log(value);
    this.setState({ viewType: value.target.value });
  };

  render() {
    const {
      locationHistorySnapshots,
      groupLocationHistorySnapshots,
      loading,
      viewType,
      enabledViews,
    } = this.state;
    const { t } = this.props;
    // find device which have history Snapshot in given time period
    let deviceIndex = groupLocationHistorySnapshots.findIndex(
      x => x.historySnapshot.pathSnapshot.length > 0,
    );
    return (
      <div>
        <div className={styles.leafletContainer}>
          {this.controllerBar()}
          <div>
            {/* eslint-disable-next-line no-nested-ternary */}
            {locationHistorySnapshots.length > 0 ? (
              <LocationHistoryMap
                locationHistorySnapshots={locationHistorySnapshots}
                view={viewType}
                enabledViews={enabledViews}
              />
            ) : deviceIndex > -1 ? (
              <LocationHistoryMap
                groupLocationHistorySnapshots={groupLocationHistorySnapshots}
                view={viewType}
              />
            ) : (
              <div>
                {!loading && (
                  <Result status="404" title={t('noHistorySnapshot_infoTxt')} />
                )}
                {loading && (
                  <div className={styles.spinContainer}>
                    <Spin />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(GeoDashboard));

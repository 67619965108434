/*
 * Copyright (C) 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export default function TooltipComponent({
  continuous,
  index,
  isLastStep,
  skipProps,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) {
  const { t } = useTranslation();

  return (
    <div {...tooltipProps} className={styles.tourGuide}>
      {step.title && <h3>{step.title}</h3>}
      <div className={styles.tourGuideContent}>{step.content}</div>
      <div className={styles.btnComponent}>
        {continuous && !isLastStep && (
          <>
            <Button
              type="primary"
              className={styles.tourGuideBtn}
              {...primaryProps}
            >
              {t('label_next')}
            </Button>
            <Button
              type="primary"
              className={styles.tourGuideSkipBtn}
              {...skipProps}
            >
              {t('label_skip')}
            </Button>
          </>
        )}
        {continuous && isLastStep && (
          <Button
            type="primary"
            className={styles.tourGuideBtn}
            {...closeProps}
          >
            {t('label_endTour')}
          </Button>
        )}
        {!continuous && (
          <Button
            type="primary"
            className={styles.tourGuideCloseBtn}
            {...closeProps}
          >
            {t('label_close')}
          </Button>
        )}
        {index > 0 && (
          <>
            <Button
              type="primary"
              className={styles.tourGuideBackBtn}
              {...backProps}
            >
              {t('label_back')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Modal, notification } from 'antd';
import { withConfigContext } from '../../../../../../../../components/ConfigContext';
import GeoFenceForm from '../GeoFenceForm';
import axios from 'axios';
import { handleApiError } from '../../../../../../../../services/utils/errorHandler';
import HtmlComments from '../../../../../../../../components/ReactHtmlComments/HtmlComments';
import { withTranslation } from 'react-i18next';

class AddGeoFence extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      isAddModalVisible: false,
    };
  }

  componentDidMount() {
    this.changeModalVisibility();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isAddModalVisible === false) {
      this.changeModalVisibility();
    }
  }

  changeModalVisibility = () => {
    let params = new URL(window.location).searchParams;
    let name = params.get('add-new-geo-fence');
    if (JSON.parse(name)) {
      this.setState({
        isAddModalVisible: true,
      });
    }
  };

  closeGeoFenceAddingModal = () => {
    window.history.pushState(null, '', location.href.split('?')[0]);
    this.setState({
      isAddModalVisible: false,
    });
  };

  onCreateGeoFence = values => {
    const { t } = this.props;
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/geo-services/geo-fence',
        JSON.stringify(values),
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 201) {
          this.closeGeoFenceAddingModal();
          this.props.fetchGeoFenceData();
          notification.success({
            message: t('api_successMsg'),
            duration: 4,
            description: t('api_createGeoFenceMsg'),
          });
        }
      })
      .catch(error => {
        handleApiError(
          error,
          t('api_addError', { label: t('home_menu_geoFence') }),
          t,
        );
        this.setState({ loading: false });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <Modal
            title={t('title_addGeoFence')}
            open={this.state.isAddModalVisible}
            onCancel={this.closeGeoFenceAddingModal}
            footer={null}
            width={900}
            maskClosable={false}
          >
            <HtmlComments
              permission={
                '/permission/admin/device-mgt/platform-configurations/view'
              }
            />
            <GeoFenceForm
              action={'Create'}
              modalVisibility={this.state.isAddModalVisible}
              callback={values => this.onCreateGeoFence(values)}
              groups={this.props.groups}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(AddGeoFence));

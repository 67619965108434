/* eslint-disable no-nested-ternary */
/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Col, Result, Row, Divider, Select, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { withConfigContext } from '../../../../components/ConfigContext';
import { RoutesContext } from '../../index';
import styles from './styles.module.css';
import Authorized from '../../../../components/Authorized';
import { withTranslation } from 'react-i18next';
import BreadcrumbComponent from '../../../../components/BreadCrumb';
import { subHours } from 'date-fns';
import IframeWrapper from '../../components/IframeWrapper';
import { getUnixTime } from '../../../../services/utils/functions';
import configs from '../../../../../public/conf/config.json';
import DateRangeDropdown from '../../components/common/DateRangeDropdown';
import HtmlComments from '../../../../components/ReactHtmlComments/HtmlComments';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

const DailyEnergyConsumptionReports = props => {
  const config = props.context;
  const deviceOptions = useSelector(state => state.deviceOption.deviceOptions);
  const defaultDevice = useSelector(state => state.deviceOption.defaultDevice);
  const selectedBranch = useSelector(state => state.branch.value);
  const { t } = props;
  const routesContext = useContext(RoutesContext);
  const [meterSerials, setMeterSerials] = useState(
    `var-serial=${defaultDevice}`
  );
  const previousDefaultDevice = useRef(null);
  const rightNow = new Date();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState([
    defaultDevice.toString()
  ]);
  const [dashboardUrl, setDashboardUrl] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const startTimestamp = urlParams.get('startTimestamp');
  const endTimestamp = urlParams.get('endTimestamp');
  const initialState =
    startTimestamp && endTimestamp
      ? {
          from: parseInt(startTimestamp, 10),
          to: parseInt(endTimestamp, 10)
        }
      : {
          from: getUnixTime(subHours(rightNow, 6)),
          to: getUnixTime(rightNow)
        };

  const [range, setRange] = useState(initialState);

  useEffect(() => {
    setMeterSerials(`var-serial=${defaultDevice}`);
    setSelectedOption([defaultDevice.toString()]);
  }, [selectedBranch]);

  useEffect(() => {
    routesContext.setCurrentRoute('allDailyEnergyConsumptionAnalytics');
    setIsLoading(true);

    // This function is used to update the URL
    const updateDashboardUrl = () => {
      setDashboardUrl(
        // eslint-disable-next-line max-len
        `${configs.grafanaConfig.dailyEnergyConsumptionAnalyticsReportsVisualization}&from=${range.from}&to=${range.to}&${meterSerials}&var-branch=${selectedBranch}`,
      );
    };

    // Check if the default device has changed
    if (!isEqual(previousDefaultDevice.current, defaultDevice)) {
      // Update the state with the new default device
      setSelectedOption([defaultDevice.toString()]);
      previousDefaultDevice.current = defaultDevice;

      // Update meterSerials with the new default device
      setMeterSerials(`var-serial=${defaultDevice}`);

      // Update the URL with the new default device
      updateDashboardUrl();
    } else {
      // Update the URL with the current meterSerials
      updateDashboardUrl();
    }
  }, [defaultDevice, meterSerials, range.from, range.to]);

  const handleSerialChange = serial => {
    if (serial.length !== 0) {
      setSelectedOption(serial);
      const serialString = `var-serial=${serial}`;
      setMeterSerials(serialString);
    }
  };

  const routes = appName => [
    {
      path: `/${appName}`,
      breadcrumbName: t('home_menu_home')
    },
    {
      breadcrumbName: t('home_menu_dailyEnergyConsumptionAnalytics')
    }
  ];

  return (
    <div>
      <HtmlComments
        permission={
          '/device-mgt/grafana/view'
        }
      />
      <Authorized
        scope={['grafana:api:view']}
        yes={
          <div>
            <PageHeader className={styles.pageHeader} ghost={false}>
              <div>
                <Row justify="space-between" align="middle" gutter={8}>
                  <Col>
                    <BreadcrumbComponent
                      breadcrumbList={routes(config.appName)}
                    />
                  </Col>
                  <Col>
                    <Row align="middle" gutter={[8, 8]} justify="end">
                      <Col className={styles.dateColStyle}>
                        <Space>
                          {startTimestamp && endTimestamp ? (
                            <DateRangeDropdown
                              startTimestamp={range.from}
                              endTimestamp={range.to}
                              getRange={data => setRange(data)}
                              closeOnBackground
                              disabled={true}
                              range={5}
                            />
                          ) : (
                            <DateRangeDropdown
                              alignDropdown="right"
                              getRange={data => setRange(data)}
                              closeOnBackground
                              range={5}
                            />
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </PageHeader>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {dashboardUrl !== '' &&
                  meterSerials !== '' &&
                  range.from &&
                  range.to && (
                    <div className="row">
                      <div className="col-md-12">
                        <IframeWrapper
                          iframeSrc={dashboardUrl}
                          height="700px"
                        />
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
            <Divider />
          </div>
        }
        no={
          <Result
            status="403"
            title={t('noPerm_accessPageTitle')}
            subTitle={t('noPerm_contactSysAdmin')}
          />
        }
      />
    </div>
  );
};

export default withConfigContext(
  withTranslation()(DailyEnergyConsumptionReports)
);

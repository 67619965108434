/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Alert, Button, Form, Input, Modal, notification } from 'antd';
import axios from 'axios';
import { withConfigContext } from '../../../../components/ConfigContext';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const buttonItemLayout = {
  wrapperCol: { span: 24, offset: 19 },
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      loading: false,
      isError: false,
      isVisible: false,
    };
  }

  onFinish = values => {
    delete values.confirm;
    this.onChangePassword(values);
  };

  onChangePassword = payload => {
    const { t } = this.props;
    axios
      .put(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        '/users/credentials',
        payload,
        { headers: { 'Content-Type': 'application-json' } },
      )
      .then(res => {
        if (res.status === 200) {
          notification.success({
            message: t('label_password_update_success_message'),
            duration: 4,
            description: t('label_password_update_success_message_description'),
          });
          this.props.onCancelChangedPassword();
          this.setState({ isError: false });

          setTimeout(() => {
            this.props.logout();
          }, 4000);
        }
      })
      .catch(error => {
        this.setState({ isError: true });
      });
  };

  render() {
    const { t } = this.props;
    const { loading, isError } = this.state;
    const {
      isChangePasswordModalVisible,
      onCancelChangedPassword,
    } = this.props;

    return (
      <div>
        <Modal
          title={t('change_password_title')}
          open={isChangePasswordModalVisible}
          onCancel={onCancelChangedPassword}
          footer={null}
          width={620}
          maskClosable={false}
        >
          <Form {...formItemLayout} onFinish={this.onFinish}>
            <Form.Item
              name="oldPassword"
              label={t('label_password_update_current_password')}
              rules={[
                {
                  required: true,
                  message: t('label_password_update_current_pass_message'),
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Alert
              style={{
                marginTop: 5,
                display: isError ? 'inline' : 'none',
              }}
              message={t('label_password_update_invalid_pass_message')}
              type="error"
            />
            <Form.Item
              name="newPassword"
              label={t('label_password_update_new_password')}
              rules={[
                {
                  required: true,
                  message: t('label_password_update_new_password_message'),
                },
                {
                  min: 5,
                  message: t(
                    'label_password_update_new_password_characters_message',
                  ),
                },
                {
                  pattern: new RegExp('^\\S+$'),
                  message: t(
                    'label_password_update_new_password_white_spaces_message',
                  ),
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label={t('label_password_update_confirm')}
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('label_password_update_confirm_message'),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t('label_password_update_mismatch_message'),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
              <Button loading={loading} type="primary" htmlType="submit">
                {t('label_change')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(ChangePassword));

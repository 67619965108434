/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

import React from 'react';
import { Col, notification, Row, Spin } from 'antd';
import axios from 'axios';
import { handleApiError } from '../../../../services/utils/errorHandler';
import DeviceFeature from './components/DeviceFeature';
import { withConfigContext } from '../../../../components/ConfigContext';
import styles from './styles.module.css';
import { withTranslation } from 'react-i18next';

class DeviceOperations extends React.Component {
  constructor(props) {
    super(props);
    this.config = this.props.context;
    this.state = {
      operations: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadOperationList(this.props.deviceType);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.deviceType !== prevProps.deviceType) {
      this.loadOperationList(this.props.deviceType);
    }
  }

  onApplyOperationToDevice = (uri, payload) => {
    const { t } = this.props;
    const { idType } = this.props;
    let operationPayload;
    if (idType === 'device') {
      if (
        (Object.keys(payload.operation).length || uri.includes('/wipe')) &&
        this.props.deviceType !== 'windows'
      ) {
        payload.deviceIDs = this.props.deviceIdentifiers;
        operationPayload = payload;
      } else {
        operationPayload = this.props.deviceIdentifiers;
      }
    } else if (idType === 'group') {
      if (
        (Object.keys(payload.operation).length || uri.includes('/wipe')) &&
        this.props.deviceType !== 'windows'
      ) {
        payload.identifiers = this.props.groupIDList;
        operationPayload = payload;
      } else {
        operationPayload = this.props.groupIDList;
      }
    }
    axios
      .post(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        uri.replace('/api', '') +
        '?type=' +
        idType,
        operationPayload,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then(res => {
        if (res.status === 201) {
          notification.success({
            message: t('label_done'),
            duration: 4,
            description: t('label_operation_triggered'),
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('label_error_retrieve_operation'), t);
      });
  };

  loadOperationList = deviceType => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });
    axios
      .get(
        window.location.origin +
        this.config.serverConfig.invoker.uri +
        this.config.serverConfig.invoker.deviceMgt +
        `/devices/device-type/${deviceType}/features`,
      )
      .then(res => {
        if (res.status === 200) {
          const operations = [];
          res.data.data.forEach(obj => {
            if (!obj.hidden) {
              operations.push(obj);
            }
          });
          this.setState({
            operations,
            isLoading: false,
          });
        }
      })
      .catch(error => {
        handleApiError(error, t('label_error_retrieve_operation '), t);
      });
  };

  render() {
    const { operations, isLoading } = this.state;
    const { t } = this.props;
    return (
      <div>
        {isLoading ? (
          <Spin
            className={styles.spinner}
            size="default"
            tip={t('label_loading')}
          />
        ) : (
          operations !== null && (
            <Row className={styles.operationsContainer}>
              {operations.map((operationData, index) => {
                return (
                  <Col span={8} className={styles.operation} key={index}>
                    <DeviceFeature
                      operationData={operationData}
                      ownerships={this.props.ownerships}
                      onApplyOperationToDevice={this.onApplyOperationToDevice}
                      deviceStatus={this.props.deviceStatus}
                      deviceType={this.props.deviceType}
                      deviceIdentifiers={this.props.deviceIdentifiers}
                    />
                  </Col>
                );
              })}
            </Row>
          )
        )}
        { }
      </div>
    );
  }
}

export default withConfigContext(withTranslation()(DeviceOperations));
